import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import circlehubspot from "../nlsimages/circlehubspot.png";


const Fueladvancebenefits = () => {
 
  return (
    <>
<section className="Fuelcardbenefits-tab">
     <div className="container">
     <div className="row text-center">
    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12"> 
    <h2>Boost your profits with our Fuel Advance Program </h2>
    <p className="established3">Partnering with National Logistics Star can provide instant funds to trucking companies 
and owner-operators to establish a profitable business. With our fuel advance program, you can concentrate on your core operations as well as enhance your business efficiency. 
</p>
    </div>
      </div>
        <Tabs>
     <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-7 col-md-6 col-12'>
                    <TabList>
      <Tab><span className='goi'>01</span><br />Financial <br className='sbility' />  stability </Tab>
      <Tab><span className='goi'>02</span><br />Fast business  growth  </Tab>
      <Tab><span className='goi'>03</span><br />Boost   your efficiency  </Tab>
      <Tab><span className='goi'>04</span><br />Deliver  <br className='sbility' />  more loads</Tab>
     
    </TabList>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-5 col-md-6 col-12'>
        <TabPanel>
        <div className="service iconbox  icon-box-style8">
    <div className="title-wrap">
  <h6>Financial stability </h6>
    <div className="content">
      <p>
      Our trucking fuel advance program allows you to get uninterrupted funds to meet your fuel requirements, allowing you to concentrate on delivering your load without delays. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  
  <div className="title-wrap">
  <h6>Fast business growth </h6>
    <div className="content">
      <p>
      Our fuel advance program relieves you from the stress of making fuel payments, thus enabling you to focus on your business growth. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  <div className="title-wrap">
  <h6>Boost your efficiency </h6>
    <div className="content">
      <p>
      Fuel advances help you save time and maximize efficiency by eliminating the waiting period for receiving invoice payments after load delivery. By getting funds before you deliver your load, you can cover on-the-road expenses and drive your business forward. 
      </p>
     
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  <div className="title-wrap">
  <h6>Deliver more loads</h6>
    <div className="content">
      <p>
      With the help of the trucking advance program, you can accept additional loads without stressing about transportation costs. We will pay you a part of your delivery value so that you can manage fuel and maintenance expenses. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
        </div>
     </div>
</Tabs>
</div>
</section>
    </>
  );
};

export default Fueladvancebenefits;
