import React, { Fragment, Suspense } from "react";
import downarrow from "../nlsimages/downarrow.png";
import fact001 from "../nlsimages/fact001.webp";
import fuel from "../nlsimages/fuel.webp";
import ware001 from "../nlsimages/ware001.webp";
import moneytransfer from "../nlsimages/moneytransfer.png";
import paperwork from "../nlsimages/paperwork.png";
import shakehand from "../nlsimages/shakehand.png";
import fees from "../nlsimages/fees.png";
import protection from "../nlsimages/protection.png";
import turn from "../nlsimages/turn.png";
import {Helmet} from "react-helmet";
import FooterTwo from "../components/FooterTwo";
import MyHeader from "../components/MyHeadr";
import Factoringbenefits from "../components/Factoringbenefits";
const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));


const Factoring = () => {
  return (
    <>
      <Fragment>
      <Suspense >
       <SupportBarOne />
<MyHeader />
<Helmet>
       <meta charSet="utf-8" />
        <title>Factoring Service For Trucking | Truck Factoring Service– NLS</title>
        <meta name="description" content="At NLS, we provide premier factoring services for trucking companies that ensure timely payments for outstanding invoices. Contact us today for truck factoring services." />
        <link rel="canonical" href=" https://nationallogisticsstar.com/Factoring" />
            </Helmet>
<section className="fulecard-bnr" style={{ backgroundImage: "url(" + ware001 + ")" }}>
    <div className="container">
        <div className="row">
        <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-10 col-12">
                <div className="dispatch-content">
                <h1
                      className="bnr-title whitetitle"
                      data-animation="fadeInUp"
                      data-delay=".5s"
                    >Truck factoring service that gets you paid faster </h1>
                    <div className="why-btn2">
  <a class="btn btn-base" href="/contact-us"><span></span> Get A Quote</a>
   </div>
                </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="dispatch-img"></div>
            </div>
        </div>
    </div>
</section>
<div className=" downarrow1">
  <img src={downarrow} className="downarrow" alt="" />
</div>
<section className="abt-insurance">
  <div className="container">
    <div className="row">
      <div className="col-lg-5 col-xxl-5 col-xl-5 col-md-5 col-12">
    <img src={fact001} alt="" className="cardfuel2" />
      </div>
      <div className='col-lg-7 col-xxl-7 col-xl-7 col-md-7 col-12 align-self-center'>
                <div className='about-inner-wrap'>
                  <div className='section-title mb-0'>
                    <h2>
                    We help turn your invoices into SAME-DAY cash.
                    </h2>
                    <p className="reader"> We know that unpaid invoices can cause huge work disruptions, especially when you depend on these funds to pay your expenses. Therefore, we have collaborated with professional factoring companies to factor your invoices so that you get paid quickly. </p>

                    <p className="reader">With our factoring services, you can get a steady flow of income that will keep your wheels rolling. Also, you get the flexibility to expand your fleet, cover operational costs and invest in new opportunities without financial constraints. </p>

                    <p className="reader">So what are you waiting for? Let us take care of your finances while you focus on the road ahead. </p>
                    <div className="why-btn3">
                    <a class="btn btn-base" href="/contact-us">  Get A Quote <span>
                  <svg
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                      fill="currentColor"
                    />
                  </svg>
                 
                </span></a>
                    </div>         
                
                  </div>
                </div>
              </div>
    </div>
  </div>
</section>
<hr />
<section className="fuel-feature">
  <div className="container">
  <div className="row" id="fuel1">
       <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">1</p>
      <h4>Submit your invoice </h4>
       <p>After delivering your load, send an invoice to us.</p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">2</p>
      <h4>Verification </h4>
       <p>Our team will verify the paperwork before forwarding it to the factoring company. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">3</p>
      <h4> Factor invoices</h4>
       <p>We will send the invoice to the factoring company to help you get an advance payment. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">4</p>
      <h4>Get  payment</h4>
       <p>The factoring company will pay you an agreed advance within 24 hours. </p>
       </div>
    </div>
  </div>
  </div>
</section>

<section className="abt-feature">
  <div className="container">
  <div className="row text-center">
    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
    <h2>Transparent freight factoring with no hidden charges </h2>
    <p className="established3">As your trusted freight factoring partner, we’re here to help trucking companies streamline their business processes. While you’re behind the wheel, delivering loads and racking up cash, we’ll be your dedicated back office, handling your collections. </p>
    </div>
      </div>
      <div className="row">
      <div className="wrapper-box">
  <div className="outer-box">
    <div className="row m-0">
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={moneytransfer} className="response" alt="" />
                   </div>
        <div className="inner-box">
           <h4>Fast cash slow </h4>
          <div className="text">
          Get immediate access to funds by converting invoices into cash. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={paperwork} className="response" alt="" />
                    </div>
        <div className="inner-box">
          <h4>Minimal paperwork </h4>
          <div className="text">
          Easy application process with minimum paperwork as well as quick approval. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={shakehand} className="response" alt="" />
                    </div>
        <div className="inner-box">
           <h4>No long-term contracts </h4>
          <div className="text">
          We offer our services with no long-term contracts, thus providing you freedom and control. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={fees} className="response" alt="" />
                  </div>
        <div className="inner-box">
           <h4>No hidden fees</h4>
          <div className="text">
          Transparent terms with no hidden or upfront charges. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={protection} className="response" alt="" />
                    </div>
        <div className="inner-box">
           <h4>Credit protection </h4>
          <div className="text">
          With us, you get optimal credit protection from client defaults and financial risks. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={turn} className="response" alt="" />
          </div>
        <div className="inner-box">
                  <h4>Flexibility</h4>
          <div className="text">
          You have the freedom to factor only the invoices of your choice. 
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
    
  </div>
</section>

<Factoringbenefits />
<FooterTwo />
      </Suspense>
       </Fragment>
    </>
  );
};

export default Factoring;
