import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import FooterTwo from "../components/FooterTwo";
import MyHeader from "../components/MyHeadr";
import SupportBarOne from "../components/SupportBarOne";
import Besttruckdispatchinner from "../components/Best-truck-dispatch";

const BlogDetailsInner = React.lazy(() =>
  import("../components/BlogDetailsInner")
);
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterBottomOne = React.lazy(() =>
  import("../components/FooterBottomOne")
);
const FooterOne = React.lazy(() => import("../components/FooterOne"));
const NavbarOne = React.lazy(() => import("../components/NavbarOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));


const Besttruckdispatch = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <SupportBarOne />
          <MyHeader />

          {/* Breadcrumb */}
          <Breadcrumb title={"BEST TRUCK DISPATCH COMPANIES IN THE USA"} />

          {/* Blog Details Inner */}
          <Besttruckdispatchinner />

          {/* Footer One */}
          <FooterTwo />

        </Suspense>
      </Fragment>
    </>
  );
};

export default Besttruckdispatch;
