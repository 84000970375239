import React from "react";
import {
  FaBuffer,
  FaFacebookF,
  FaGlobe,
  FaInstagram,
  FaPhoneAlt,
  FaRegEnvelope,
  FaTwitter,
  FaUserAlt,
  FaYoutube,
} from "react-icons/fa";

const SupportBarOne = () => {
  return (
    <>
      {/* support bar area start */}
      <div className='support-bar'>
        <div className='container'>
          <div className='row reorder-xs clearfix'>
            <div className='col-lg-6 align-self-center'>
              <div className='support-left'>
                {/* support left start */}
                <ul>
                    <li>
                    <a href='mailTo:info@nationallogisticsstar.com'>
                      <FaRegEnvelope />
               info@nationallogisticsstar.com
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href='tel:+1 253-372-2778'>
                      {" "}
                      <FaPhoneAlt />
                      +1 253-372-2778
                    </a>
                  </li>
                </ul>
              </div>
              {/* support left end */}
            </div>
            <div className='col-lg-6'>
              <div className='support-right'>
                {" "}
                {/* support right start */}
                <ul>
                 
                  <li>
                    <ul className='social-link'>
                      <li>
                        <a href='https://www.facebook.com/people/National-Logistics-Star/61555425780354/' className='facebook'>
                          {" "}
                          <FaFacebookF />
                        </a>
                      </li>
                      <li>
                        <a href='https://www.instagram.com/nationallogisticsstar/' className='instagram'>
                          <FaInstagram />
                        </a>
                      </li>
                    </ul>
                  </li>
                
                </ul>
              </div>
              {/* support right end */}
            </div>
          </div>
        </div>
      </div>
      {/* support bar area end */}
    </>
  );
};

export default SupportBarOne;
