import React from "react";
import { Link } from "react-router-dom";
import support from "../nlsimages/support.png";
import white from "../../src/nlsimages/white.png";
const Cta = () => {
  return (
    <>
   <section className="Ctainjury">
    <div className="container">
        <div className="row">
        <div className="col-xxl-4 col-xl-4 col-md-5 col-12">
    <div className="injured">
    <div className="phonecall">
        <img src={support} className="phonell" alt="" />
    </div>
     <h2>Need Help? <br /><span>Call Us.</span></h2>
    </div>
      </div>
      <div className="col-xxl-8 col-xl-8 col-md-7 col-12">
      <div className="believe">
      <h3>Struggling with challenges? </h3>
 <p className="secure">We’ve got solutions to overcome your trucking challenges. Contact us today. </p>
   
      <Link to="/contact-us" className="tp-btn-border">
              Make an Inquiry
                <span>
                  <svg
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                      fill="currentColor"
                    />
                  </svg>
                 
                </span>
              </Link>
 
           </div>
           </div>
        </div>
    </div>
   </section>
    </>
  );
};

export default Cta;
