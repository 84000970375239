import React, { Fragment, Suspense } from "react";
import downarrow from "../nlsimages/downarrow.png";
import fueladvances01 from "../nlsimages/fueladvances01.webp";
import fastdelivery from "../nlsimages/fastdelivery.png";
import budget from "../nlsimages/budget.png";
import advancemoney from "../nlsimages/advancemoney.png";
import eSupport from "../nlsimages/eSupport.png";
import hotline from "../nlsimages/hotline.png";
import paperwork from "../nlsimages/paperwork.png";

import Fuelcardbenefits from "../components/Fuelcardbenefits";
import fuel from "../nlsimages/fuel.webp";
import FooterTwo from "../components/FooterTwo";
import MyHeader from "../components/MyHeadr";
import Fueladvancebenefits from "../components/Fueladvancebenefits";
import {Helmet} from "react-helmet";
const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));


const Fueladvances = () => {
  return (
    <>
      <Fragment>
      <Suspense >
       <SupportBarOne />
<MyHeader />
        <Helmet>
       <meta charSet="utf-8" />
        <title>Advanced Fuel Services | Advanced Fueling Services – NLS</title>
        <meta name="description" content="NLS is your one-stop destination for top-notch advanced fueling services. Our advanced fuel services provide instant funds to help you cover your fuel expenses." />
        <link rel="canonical" href="https://nationallogisticsstar.com/Fuel-advances" />
            </Helmet>
<section className="Fueladvances-bnr" style={{ backgroundImage: "url(" + fuel + ")" }}>
    <div className="container">
        <div className="row">
        <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-10 col-12">
                <div className="dispatch-content">
                <h1
                      className="bnr-title whitetitle"
                      data-animation="fadeInUp"
                      data-delay=".5s"
                    >Get immediate funds to cover advanced fuel services, fuel costs, and on-road expenses </h1>
                    <div className="why-btn2">
                    <a class="btn btn-base" href="/contact-us">  Get A Quote <span>
                  <svg
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                      fill="currentColor"
                    />
                  </svg>
                 
                </span></a>
   </div>
                </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="dispatch-img"></div>
            </div>
        </div>
    </div>
</section>
<div className=" downarrow1">
  <img src={downarrow} className="downarrow" alt="" />
</div>
<section className="abt-insurance">
  <div className="container">
    <div className="row">
      <div className="col-lg-5 col-xxl-5 col-xl-5 col-md-5 col-12">
      <img src={fueladvances01} alt="" className="cardfuel2" />
      </div>
      <div className='col-lg-7 col-xxl-7 col-xl-7 col-md-7 col-12 align-self-center'>
                <div className='about-inner-wrap'>
                  <div className='section-title mb-0'>
                    <h2>
                    Fuel advances that keep your fleet rolling 
                    </h2>
                    <p className="reader">The rising fuel costs pose a significant challenge for owner-operators and truckers, leaving them with insufficient or no funds to spare. That’s why National Logistics Star provides fuel advances to trucking professionals to help them get instant funds to purchase fuel for on-time deliveries. </p>

                    <p className="reader">Our fuel advances simplify logistics, thus reducing the financial burden for drivers and fleets. With us, you can have peace of mind, knowing that if you don’t have sufficient funds to reach your destination, we can help you get an advance to ensure the timely delivery of your load. </p>

                    <p className="reader">Whether you’re behind the wheel or awaiting shipment, we can help you get quick funds without any delay or complex paperwork. </p>
                    <div className="why-btn3">
                    <a class="btn btn-base" href="/contact-us"><span></span> Get A Quote</a>
                    </div>         
                
                  </div>
                </div>
              </div>
    </div>
  </div>
</section>
<hr />
<section className="fuel-feature">
  <div className="container">
  <div className="row" id="fuel1">
       <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">1</p>
      <h4>Contact us </h4>
       <p>Reach out to our team with your requirements for funds. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">2</p>
      <h4>Submit documentation </h4>
       <p>Send us a copy of your signed BOL & rate confirmation. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">3</p>
      <h4>Quick verification </h4>
       <p>We will verify the details and confirm the pickup or delivery. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">4</p>
      <h4>Receive fuel advances</h4>
       <p>Once approved, you’ll receive funds in your account. </p>
       </div>
    </div>
  </div>
  </div>
</section>

<section className="abt-feature">
  <div className="container">
  <div className="row text-center">
    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
    <h2>Hassle-free Fuel advances for uninterrupted journeys</h2>
    <p className="established3">At National Logistics Star, we provide extensive solutions for fuel advances to fleets of all sizes. With our fuel advance programs, we help independent drivers, owner-operators, large trucking fleets and everyone in between to run their operations smoothly without worrying about fuel shortages during deliveries or making it back home. 

</p>
    </div>
      </div>
      <div className="row">
      <div className="wrapper-box">
  <div className="outer-box">
    <div className="row m-0">
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={budget} className="response" alt="" />
                   </div>
        <div className="inner-box">
          <h4>Same day funding </h4>
          <div className="text">
          Get quick funds without waiting for long hours. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={advancemoney} className="response" alt="" />
                    </div>
        <div className="inner-box">
            <h4>Up to 50% fuel advance </h4>
          <div className="text">
          Receive fuel advances of up to 50% of delivery value.
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={fastdelivery} className="response" alt="" />
         </div>
        <div className="inner-box">
          <h4>For all fleet sizes</h4>
          <div className="text">
          We offer fuel advances to owners, operators, independent drivers & large fleets. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={eSupport} className="response" alt="" />
                  </div>
        <div className="inner-box">
           <h4>Support for emergencies</h4>
          <div className="text">
          Get financial support during emergency situations or unforeseen circumstances. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={hotline} className="response" alt="" />
                    </div>
        <div className="inner-box">
            <h4>Dedicated support </h4>
          <div className="text">
          Receive assistance from reliable experts throughout the process. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={paperwork} className="response" alt="" />
          </div>
        <div className="inner-box">
            <h4>No complex paperwork </h4>
          <div className="text">
          Experience a seamless process without complicated paperwork. 
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
    
  </div>
</section>
<Fueladvancebenefits />

<FooterTwo />
      </Suspense>
       </Fragment>
    </>
  );
};

export default Fueladvances;
