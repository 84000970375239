import React from "react";
import oneroperator from "../nlsimages/oneroperator.png";
import oneroperator2 from "../nlsimages/oneroperator2.png";
import serivceimg1 from "../../src/nlsimages/serivceimg1.png";
import serivceimg2 from "../../src/nlsimages/serivceimg2.png";
import serivceimg3 from "../../src/nlsimages/serivceimg3.png";
import serivceimg4 from "../../src/nlsimages/serivceimg4.png";
import  FreightBrokers from "../../src/nlsimages/FreightBrokers.png";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
const Newserivces = () => {
  return (
    <>
    <section className="Newserivces">
        <div className="container">
            <div className="row text-center" id="mainrow">
                <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                <h2>Solutions that make your hauling effortless</h2>
                <p className="whenlooking"> At NLS, we ensure your hauling experience is smooth and seamless. <br />Our trucking solutions help you focus on the road while we handle your back-end worries.</p>
                </div>
              
            </div>
            <Tabs>
            <div className="row" id="scnd-row">
            <div className="btn-column col-xl-3 col-xxl-3 col-md-4 col-lg-3 col-12">
            <div className="inner-column">
        <TabList>
      <Tab>Dispatch Services</Tab>
      <Tab>Factoring Services</Tab>
      <Tab>Fuel-card</Tab>
      <Tab>Compliance</Tab>
      
    </TabList>
    </div>
            </div>
            <div
      className="content-column col-xl-9 col-xxl-9 col-md-8 col-lg-9 col-12 wow zoomIn animated"
      style={{ visibility: "visible", animationName: "zoomIn" }}
    >
   <div className="tabs-content">
     <TabPanel>
           <div className="work-block">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image overlay-anim">
                  <img src={FreightBrokers} className=" FreigBrokers" alt="" />
                  </figure>
                </div>
                <div className="content-box">
                  <div className="icon-box">
                  <img src={serivceimg1} className="serivceimg1" alt="" />
                    <i className="icon flaticon-distribution-center-2" />
                    <span className="count">.1</span>
                  </div>
                  <Link to='/'>
                  <h3 className="title2">Dispatch Services</h3>
                  </Link>
                  <div className="text">
                  From finding top-paying loads to negotiating rates, setting carrier packets, performing credit checks and managing paperwork, we handle every aspect of your back-office operations. 
                  </div>
                </div>
              </div>
            </div>
           
    </TabPanel>
    <TabPanel>
    <div className="work-block">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image overlay-anim">
                  <img src={oneroperator} className=" FreigBrokers" alt="" />
                  </figure>
                </div>
                <div className="content-box">
                  <div className="icon-box">
                  <img src={serivceimg2} className="serivceimg1" alt="" />
                    <i className="icon flaticon-distribution-center-2" />
                    <span className="count">.2</span>
                  </div>
                  <Link to='/'>
                  <h3 className="title2">Factoring Services</h3>
                  </Link>
                  <div className="text">
                  With our factoring services, we ensure that you get immediate funds against outstanding invoices to cover your operational expenses, fuel costs and maintenance costs without delay. 
                  </div>
                </div>
              </div>
            </div>
    </TabPanel>
    <TabPanel>
    <div className="work-block">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image overlay-anim">
                  <img src={oneroperator2} className=" FreigBrokers" alt="" />
                  </figure>
                </div>
                <div className="content-box">
                  <div className="icon-box">
                  <img src={serivceimg3} className="serivceimg1" alt="" />
                    <i className="icon flaticon-distribution-center-2" />
                    <span className="count">.3</span>
                  </div>
                  <Link to='/'>
                  <h3 className="title2">Fuel Card  Service</h3>
                  </Link>
                  <div className="text">
                  Our diverse range of fuel card options allows you to enjoy exclusive discounts and rewards at fuel stations, thus helping you minimize operational costs and increase profitability. 

                  </div>
                </div>
              </div>
            </div>
    </TabPanel>
    <TabPanel>
    <div className="work-block">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image overlay-anim">
                  <img src={oneroperator2} className=" FreigBrokers" alt="" />
                  </figure>
                </div>
                <div className="content-box">
                  <div className="icon-box">
                  <img src={serivceimg4} className="serivceimg1" alt="" />
                    <i className="icon flaticon-distribution-center-2" />
                    <span className="count">.4</span>
                  </div>
                  <Link to='/'>
                  <h3 className="title2">Compliance Services</h3>
                  </Link>
                  <div className="text">
                  Our dedicated team of experts constantly monitor the latest safety and DOT regulations, making sure that you stay compliant with them. From getting MC numbers to IFTA registrations, we’ll handle all your compliance needs. 
                  </div>
                </div>
              </div>
            </div>
    </TabPanel>
    </div>
              </div>
              </div>
</Tabs>
      
        </div>
    </section>
    </>
  );
};

export default Newserivces;
