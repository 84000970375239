import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import circlehubspot from "../nlsimages/circlehubspot.png";


const Fuelcardbenefits = () => {
 
  return (
    <>
<section className="Fuelcardbenefits-tab">
     <div className="container">
     <div className="row text-center">
    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12"> 
    <h2>Enjoy big discounts with NLS fuel cards </h2>
    <p className="established3">Experience effortless fleet management and enjoy a multitude of benefits with our fuel cards. Check out the advantages that await you when you partner with National Logistics Star. </p>
    </div>
      </div>
        <Tabs>
     <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-7 col-md-6 col-12'>
                    <TabList>
      <Tab><span className='goi'>01</span><br />Huge <br className='huge' />discounts </Tab>
      <Tab><span className='goi'>02</span><br />Seamless convenience </Tab>
      <Tab><span className='goi'>03</span><br />Unmatched flexibility </Tab>
      <Tab><span className='goi'>04</span><br />Efficient fuel advances</Tab>
     
    </TabList>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-5 col-md-6 col-12'>
        <TabPanel>
        <div className="service iconbox  icon-box-style8">
    <div className="title-wrap">
  <h6>Huge discounts </h6>
    <div className="content">
      <p>
      Our fuel card services offer huge discounts on every gallon, thus helping you save thousands of dollars on your fuel. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  
  <div className="title-wrap">
  <h6>Seamless convenience </h6>
    <div className="content">
      <p>
      With our cards, your drivers can fuel up swiftly at a network of partner stations across the country without any paperwork hassles and cash transactions. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  <div className="title-wrap">
  <h6>Unmatched flexibility  </h6>
    <div className="content">
      <p>
      We offer unparalleled flexibility, allowing you to customize spending limits, restrict fuel types, and control your fleet's expenses based on your specific requirements.
      </p>
     
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  <div className="title-wrap">
  <h6>Efficient fuel advances</h6>
    <div className="content">
      <p>
      We ensure quick and efficient solutions to meet your immediate fueling needs. At NLS, we provide funding directly to your fuel cards to ensure that you have the necessary funds to meet your urgent needs. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
        </div>
     </div>
</Tabs>
</div>
</section>
    </>
  );
};

export default Fuelcardbenefits;
