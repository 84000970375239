import React, { Fragment, Suspense } from "react";
import downarrow from "../nlsimages/downarrow.png";
import fuelbox from "../nlsimages/fuelbox.webp";
import fastdelivery from "../nlsimages/fastdelivery.png";
import fuel00 from "../nlsimages/fuel00.webp";
import Fuelcardbenefits from "../components/Fuelcardbenefits";
import FooterTwo from "../components/FooterTwo";
import MyHeader from "../components/MyHeadr";
import yes from "../nlsimages/yes.png";
import fadelivery from "../nlsimages/fadelivery.png";
import gasstation from "../nlsimages/gasstation.png";
import money from "../nlsimages/money.png";
import discountcoupon from "../nlsimages/discountcoupon.png";
import Fuelmonitoring from "../nlsimages/Fuelmonitoring.png";
import {Helmet} from "react-helmet";
const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));


const Fuelcard = () => {
  return (
    <>
      <Fragment>
      <Suspense >
       <SupportBarOne />
<MyHeader />
<Helmet>
       <meta charSet="utf-8" />
        <title>Best Fuel Card For Small Trucking Company | Company Fuel Cards</title>
        <meta name="description" content="We provide the best fuel cards for small trucking companies that offer huge discounts on fuel at nationwide fuel stations and enable you to track your fuel expenses." />
        <link rel="canonical" href="https://nationallogisticsstar.com/Fuel-card" />
            </Helmet>
<section className="fulecard-bnr" style={{ backgroundImage: "url(" + fuel00 + ")" }}>
    <div className="container">
        <div className="row">
        <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-10 col-12">
                <div className="dispatch-content">
                <h1
                      className="bnr-title whitetitle"
                      data-animation="fadeInUp"
                      data-delay=".5s"
                    >Save big on your fuel expenses with our exclusive company fuel cards</h1>
                    <div className="why-btn2">
                    <a class="btn btn-base" href="/contact-us">  Get A Quote <span>
                  <svg
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                      fill="currentColor"
                    />
                  </svg>
                 
                </span></a>
   </div>
                </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="dispatch-img"></div>
            </div>
        </div>
    </div>
</section>
<div className=" downarrow1">
  <img src={downarrow} className="downarrow" alt="" />
</div>
<section className="abt-insurance">
  <div className="container">
    <div className="row">
      <div className="col-lg-5 col-xxl-5 col-xl-5 col-md-5 col-12">
    <img src={fuelbox} alt="" className="cardfuel2" />
      </div>
      <div className='col-lg-7 col-xxl-7 col-xl-7 col-md-7 col-12 align-self-center'>
                <div className='about-inner-wrap'>
                  <div className='section-title mb-0'>
                    <h2>
                    Drive more miles with less fuel costs.
                    </h2>
                    <p className="reader">At National Logistics Star, we are aware of the substantial impact that fuel expenses have on truckers and trucking companies. We understand the challenges of managing and controlling these expenses, which is why we are dedicated to reducing your burden with our fuel card services. </p>

                    <p className="reader">While other companies are increasing their fees, we stand firm on our commitment to providing unparalleled discounts at fuel stations, ensuring you pay less for every gallon of fuel.</p>

                    <p className="reader">Regardless of your fleet size, we continue to provide transparent and low costs for fuel cards to help you drive more miles and expand your business horizons while keeping your fuel costs minimum. </p>
                    <div className="why-btn3">
                    <a class="btn btn-base" href="/contact-us"><span></span> Get A Quote</a>
                    </div>         
                
                  </div>
                </div>
              </div>
    </div>
  </div>
</section>
<hr />
<section className="fuel-feature">
  <div className="container">
  <div className="row" id="fuel1">
       <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">1</p>
      <h4>Sign up </h4>
       <p>Give us basic details about your business, fleet size and fueling needs. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">2</p>
      <h4>Approval </h4>
       <p>Once we receive the application, our dedicated team will review and approve your card within 24 hours. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">3</p>
      <h4> Dispatch </h4>
       <p>When you’re approved, your fuel card will be dispatched to your address. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">4</p>
      <h4>Activation </h4>
       <p> Now, you can activate and enjoy unbeatable savings on your fuel expenses. </p>
       </div>
    </div>
  </div>
  </div>
</section>

<section className="abt-feature">
  <div className="container">
  <div className="row text-center">
    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
    <h2>Key features of our Fuel cards</h2>
    <p className="established3">NLS fuel card services offer trucking businesses increased control, significant savings and enhanced security for managing fuel expenses. Discover the key features of our fuel cards that help you experience fueling convenience and maximized cost savings. </p>
    </div>
      </div>
      <div className="row">
      <div className="wrapper-box">
  <div className="outer-box">
    <div className="row m-0">
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={yes} className="response" alt="" />
            </div>
        <div className="inner-box">
           <h4>Nationwide Acceptance </h4>
          <div className="text">
          Our fuel cards are accepted at a vast number of fuel stations across the country. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={fadelivery} className="response" alt="" />
                    </div>
        <div className="inner-box">
            <h4>Enhanced Security"</h4>
          <div className="text">
          Our fuel cards come with advanced features to ensure your transaction security. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={gasstation} className="response" alt="" />
                    </div>
        <div className="inner-box">
             <h4>Fuel discounts</h4>
          <div className="text">
          Get up to $.50 off per gallon at selected fuel stations.
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={money} className="response" alt="" />
                  </div>
        <div className="inner-box">
           <h4>No setup fee</h4>
          <div className="text">
          You can get started without any extra financial burden. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={discountcoupon} className="response" alt="" />
                    </div>
        <div className="inner-box">
            <h4>Service discounts </h4>
          <div className="text">
          Access exclusive maintenance and tire discounts at 200+ nationwide centres. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={Fuelmonitoring} className="response" alt="" />
          </div>
        <div className="inner-box">
             <h4>Fuel monitoring </h4>
          <div className="text">
          You can track the individual fuel usage of every truck in your fleet. 
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
    
  </div>
</section>
<Fuelcardbenefits />

<FooterTwo />
      </Suspense>
       </Fragment>
    </>
  );
};

export default Fuelcard;
