import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import circlehubspot from "../nlsimages/circlehubspot.png";


const Factoringbenefits = () => {
 
  return (
    <>
<section className="Fuelcardbenefits-tab">
     <div className="container">
     <div className="row text-center">
    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12"> 
    <h2>Factoring services for all-size fleets </h2>
    <p className="established3">At National Logistics Star, we provide flexible fleet factoring services for every fleet size. Whether you’re an owner-operator or a large fleet owner, we help you manage your cash flow and maintain steady business operations. </p>
    </div>
      </div>
        <Tabs>
     <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-7 col-md-6 col-12'>
                    <TabList>
      <Tab><span className='goi'>01</span><br />Owner- <br className='operors' />operators </Tab>
      <Tab><span className='goi'>02</span><br />Diverse trucking fleet </Tab>
      <Tab><span className='goi'>03</span><br /> Freight <br className='operors' />brokers </Tab>
      <Tab><span className='goi'>04</span><br />Trucking companies</Tab>
     
    </TabList>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-5 col-md-6 col-12'>
        <TabPanel>
        <div className="service iconbox  icon-box-style8">
    <div className="title-wrap">
  <h6>Owner-operators</h6>
    <div className="content">
      <p>
      Our freight factoring services provide owner-operators with quick access to funds to help them cover operational expenses, from fuel and maintenance to unexpected repairs. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  
  <div className="title-wrap">
  <h6>Diverse trucking fleet </h6>
    <div className="content">
      <p>
      With our freight factoring services, small and medium-sized trucking companies can manage their payroll expenses, maintain their fleets and invest in growth opportunities. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  <div className="title-wrap">
  <h6> Freight brokers </h6>
    <div className="content">
      <p>
      Our services allow freight brokers to convert their unpaid shipper invoices into cash, providing them with funds to meet operational expenses as well as expand their business. 
      </p>
     
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  <div className="title-wrap">
  <h6>Trucking companies</h6>
    <div className="content">
      <p>
      With freight factoring services from NLS, large trucking companies can get consistent cash flow that allows them to optimize their business operations as well as focus on broadening their business horizons. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
        </div>
     </div>
</Tabs>
</div>
</section>
    </>
  );
};

export default Factoringbenefits;
