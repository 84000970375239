import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


const Dispatchingbenefits = () => {
 
  return (
    <>
<section className="Fuelcardbenefits-tab">
     <div className="container">
     <div className="row text-center">
    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12"> 
    <h2>Make your trucking business thrive with NLS </h2>
    <p className="established3"> At NLS, we provide reliable dispatching services because we have extensive knowledge of the ins and outs of the trucking industry. We focus on a comprehensive approach and provide “full-fledged” services that save you time as well as money. Here are a few benefits you will experience when you choose us for truck dispatching services. 
</p>
    </div>
      </div>
        <Tabs>
     <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-7 col-md-6 col-12'>
                    <TabList>
      <Tab><span className='goi'>01</span><br />Preferred lanes</Tab>
      <Tab><span className='goi'>02</span><br />No forced dispatch   </Tab>
      <Tab><span className='goi'>03</span><br />No hidden fees </Tab>
      <Tab><span className='goi'>04</span><br />24/7 dispatch </Tab>
     
    </TabList>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-5 col-md-6 col-12'>
        <TabPanel>
        <div className="service iconbox  icon-box-style8">
    <div className="title-wrap">
  <h6>Preferred lanes</h6>
    <div className="content">
      <p>
      At National Logistics Star, you enjoy the flexibility to choose the route that matches your preferences. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  
  <div className="title-wrap">
  <h6>No forced dispatch </h6>
    <div className="content">
      <p>
      We understand the unique needs of truckers. That’s why we allow them the freedom to pick the load of their choice. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  <div className="title-wrap">
  <h6>No hidden fees</h6>
    <div className="content">
      <p>
      We believe in open and clear communication. Therefore, while working with us, you can be confident that there are no hidden charges or unwanted surprises. 
      </p>
     
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  <div className="title-wrap">
  <h6>24/7 dispatch </h6>
    <div className="content">
      <p>
      With our round-the-clock dispatch services, you can rest assured that you have reliable support for every situation. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
        </div>
     </div>
</Tabs>
</div>
</section>
    </>
  );
};

export default Dispatchingbenefits;
