import React, { Fragment, Suspense } from "react";
import FooterTwo from "../components/FooterTwo";
import MyHeader from "../components/MyHeadr";

const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));


const Privacypolicy = () => {
  return (
    <>
      <Fragment>
      <Suspense >
       <SupportBarOne />
      <MyHeader />
      <section className="Logistics-policy-bnr">
      <div className="container">
        <div className="row">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
        <h2>Privacy Policy</h2>
        </div>
        </div>
      </div>
      </section>
<section className="Logistics-policy">
    <div className="container">
        <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
              <h2>Privacy Policy</h2>
              <p>National Logistics Star, located at 507 DENALI PASS STE 601 CEDAR PARK, TX 78613 (referred to as “NLS”, “Us”, “We”, “Our”, and “Website” within this Privacy Policy describes our understanding and significance of the personal information you share with us. </p>
<p>This Privacy Policy outlines how we gather, use, and disclose information from users of this Website. The Policy applies to our services, whether accessed through the Website or otherwise. 
</p>
<p>You’re requested to read and understand the Privacy Policy before providing us with your personal information. By continuing to use the Services, you are consenting that you have read and understood this Privacy Policy and agree to be legally bound by it. If you do not agree with all of the terms of this Privacy Policy, please stop using our website. </p>
<h3>General information </h3>
<p>This Privacy Policy applies to the:</p>
        <ol>
          <li>Website Visitors:<br />
          For the purposes of this Policy, "User," "you," "your," and "yours" refer to any individual visiting our Website, intending to access our services. </li>
          <li>Electronic Communication: <br /> Users connecting with us via email, text, and other electronic messages exchanged between you and this Website are subject to this Policy.</li>
          <li>Mobile and Desktop Applications: <br />  If applicable, this Policy applies to interactions facilitated through mobile and desktop applications downloaded from this Website. These applications provide dedicated, non-browser-based interaction between you and our platform.</li>
          <li>Third-Party Websites and Services: <br /> Interactions with our advertising and applications on third-party websites and services are included within the scope of this Policy, provided those applications are affiliated with us.</li>
              </ol>
<h3>What information we collect </h3>
<p>To fulfill our obligations under the applicable Terms of Use, we may request your Personal Data, which includes, but is not limited to, your personal information, such as first and last name, email address, physical address, and telephone number.</p>
<p>We collect this information when provided directly by you through various channels, such as requesting information, registering with our Services, signing up for a service, sharing User Shared Content, or emailing us. We make clear efforts to inform you when and why this information is being collected.</p>
<p>Additionally, we automatically collect information as users navigate and utilize our Services, including:</p>
          <ol>
          <li>General Statistical Data such as the date and time of service access, pages viewed, and time spent using our website. </li>
          <li>Device and Browsing Information including, IP address (Internet address of a computer), computer and system identification, web browser type and version, referrer addresses and other server log details.</li>
          <li>Cookies and Similar Technologies.</li>
          </ol>

          <h3>How we use your information </h3>
          <p>We may utilize the information we collect from you or that you provide to us, including your personal details, in the following ways:</p>
          <ol>
          <li>To support NLS and/or its Affiliates' business objectives. </li>
          <li>To provide you with the information, products, and services you specifically request from us.</li>
          <li>To fulfill any other purpose for which you willingly provide the information to us.</li>
          <li>To notify you about changes in the Services, its governing documents, or any products or services offered through the Services.</li>
          <li>To enable your involvement in surveys or interactive sections.</li>
          <li>To keep you informed about our and/or our Affiliates' programs, products, services, or those of third parties that align with your interests.</li>
          <li>To engage with you concerning your use of the Services and to provide updates about our business.</li>
          <li>For any other legitimate purpose for which you grant consent.</li>
          <li>To aggregate user information for analytical purposes, ensuring enhancement and improvement of our services.</li>
          </ol>

          <h3>How we disclose your personal information </h3>
          <p>In presenting our Services and its contents to you, we may utilize and share the information we collect from you, including personal details, in the following ways:</p>
          <ol>
          <li>To fulfill the purpose for which you provide the information, as well as any other disclosed purposes and those to which you consent. </li>
          <li>Disclosure to our affiliates, allowing them to use and disclose your information to meet our business objectives.</li>
          <li>Engagement of companies, service providers, and our own Affiliates to perform functions on our behalf, such as maintaining the services, collecting information, and fulfilling orders. Your information may be disclosed to these entities for them to perform necessary functions for us.</li>
          <li>To protect our and our Affiliates’ safety, rights, property, and that of others, to enforce and/or report abuse or violation of the Services. </li>
          <li>To carry out our obligations and enforce our rights arising from contracts entered into between you and us and/or our Affiliates.</li>
          <li>To comply with court orders, legal processes, and government or regulatory requests.</li>
          <li>To protect you from fraud and unauthorized practices. </li>
          </ol>

          <h3>How we secure your information </h3>
          <p>We employ robust security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. Our systems are regularly updated and monitored to maintain the highest level of security. However, no transmission of data over the internet is 100% secure. Therefore, we cannot guarantee the absolute security of data transmitted over the Internet.</p>

          <h3>Data protection rights </h3>
          <p>You have the right to access, update, delete or object to the use of your information. If you wish to exercise these rights, please reach out to us. </p>

          <h3>Changes to the Policy </h3>
          <p>We reserve the right to modify our privacy practices at any time. You’re requested to visit the page regularly to stay updated with our privacy practices.</p>

          <h3>Contact us </h3>
          <p>If you have any questions or queries about our privacy practices, please reach out to us via call or email at info@nationallogisticsstar.com </p>
        

            </div>
        </div>
    </div>
</section>

<FooterTwo />
      </Suspense>
       </Fragment>
    </>
  );
};

export default Privacypolicy;
