import React from 'react';



const Featurefaq = () => {
 
  return (
<>
<section className='Updatefaq'>
             
  <div className="accordion" id="myAccordion">
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingOne">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseOne"
        >
 5+ years of experience in trucking and logistics 
        </button>
      </h2>
      <div
        id="collapseOne"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          We bring our extensive experience of more than 5 years in the trucking and logistics industry to ensure your success on every stretch of the road. 
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTwo">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
        >
Complete Transparency 
        </button>
      </h2>
      <div
        id="collapseTwo"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          We believe in open communication, providing you with clear and straightforward information about our pricing and services. With us, you’ll have complete access to all documentation, including rate cons, emails and setup packets. 

          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingThree">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
        >
  Dedicated, experienced and knowledgeable dispatchers 
        </button>
      </h2>
      <div
        id="collapseThree"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Our team comprises experienced dispatchers constantly working to find you top-paying loads, optimizing routes and handling your paperwork headaches. 
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingFour">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFour"
        >
   24/7 support and assistance 
        </button>
      </h2>
      <div
        id="collapseFour"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          Our knowledgeable dispatchers work round the clock to ensure that you have continuous assistance, guidance and solutions whenever you need them. 
          </p>
        </div>
      </div>
    </div>
      <div className="accordion-item">
      <h2 className="accordion-header" id="headingFive">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseFive"
        >
  Vast and carefully vetted broker network 
        </button>
      </h2>
      <div
        id="collapseFive"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          With over 5 years of industry experience, we’ve created strong relationships with trusted brokers who are aware of the quality of our services, thus helping our carriers get top-notch offers. 
          </p>
        </div>
      </div>
    </div>
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingSix">
        <button
          type="button"
          className="accordion-button collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSix"
        >
No hidden costs or surprises
        </button>
      </h2>
      <div
        id="collapseSix"
        className="accordion-collapse collapse"
        data-bs-parent="#myAccordion"
      >
        <div className="card-body">
          <p>
          At NLS, we never charge any hidden or additional fees. When you work with us, you’ll receive a clear rate without any surprise charges. 
          </p>
        </div>
      </div>
    </div>
  
  </div>


</section>

  </>  )
}

export default Featurefaq