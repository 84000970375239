import React, { Fragment, Suspense } from "react";
import downarrow from "../nlsimages/downarrow.png";
import fastdelivery from "../nlsimages/fastdelivery.png";
import authority from "../nlsimages/authority.png";
import expertise from "../nlsimages/expertise.png";
import drugs from "../nlsimages/drugs.png";
import audit from "../nlsimages/audit.png";
import eSupport from "../nlsimages/eSupport.png";

import Comp001 from "../../src/nlsimages/Comp001.webp";
import compliancebnr from "../nlsimages/compliancebnr.webp";
import Fuelcardbenefits from "../components/Fuelcardbenefits";
import FooterTwo from "../components/FooterTwo";
import MyHeader from "../components/MyHeadr";
import Compliancebenefits from "../components/Compliancebenefits";
import {Helmet} from "react-helmet";
const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));


const Compliance = () => {
  return (
    <>
      <Fragment>
      <Suspense >
       <SupportBarOne />
<MyHeader />
<Helmet>
       <meta charSet="utf-8" />
        <title>Trucking Compliance Services | Trucking Company Compliance – NLS</title>
        <meta name="description" content="Struggling to stay ahead of trucking regulations? NLS provides top trucking compliance services to help trucking companies meet their compliance requirements." />
        <link rel="canonical" href="https://nationallogisticsstar.com/Compliance" />
            </Helmet>
<section className="Compliance-bnr" style={{ backgroundImage: "url(" + compliancebnr + ")" }}>
    <div className="container">
        <div className="row">
        <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-10 col-12">
                <div className="dispatch-content">
                <h1
                      className="bnr-title whitetitle"
                      data-animation="fadeInUp"
                      data-delay=".5s"
                    >Expert trucking compliance services that keep you ahead of trucking regulations </h1>
                    <div className="why-btn2">
  <a class="btn btn-base" href="/contact-us"><span></span> Get A Quote</a>
   </div>
                </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="dispatch-img"></div>
            </div>
        </div>
    </div>
</section>
<div className=" downarrow1">
  <img src={downarrow} className="downarrow" alt="" />
</div>
<section className="abt-insurance">
  <div className="container">
    <div className="row">
      <div className="col-lg-5 col-xxl-5 col-xl-5 col-md-5 col-12">
    <img src={Comp001} alt="" className="distruck" />
      </div>
      <div className='col-lg-7 col-xxl-7 col-xl-7 col-md-7 col-12 align-self-center'>
                <div className='about-inner-wrap'>
                  <div className='section-title mb-0'>
                    <h2>
                    We don’t let regulatory complexities slow you down.
                    </h2>
                    <p className="reader">At National Logistics Star, we know that adhering to rules and regulations can be an overwhelming burden for trucking companies and owner-operators. That’s why we’re here to help you stay compliant with the ever-changing FMCSA trucking regulations. </p>

                    <p className="reader">Our trucking compliance services are designed to keep your trucking business in compliance with federal, state and local regulations, ensuring seamless operations besides helping you avoid penalties. </p>

                    <p className="reader">With NLS, you can rest assured that all aspects of your transportation compliance and operating authority have been taken care of by our expert team.</p>
                    <div className="why-btn3">
                    <a class="btn btn-base" href="/contact-us"><span></span> Get A Quote</a>
                    </div>         
                
                  </div>
                </div>
              </div>
    </div>
  </div>
</section>
<hr />
<section className="fuel-feature">
  <div className="container">
  <div className="row" id="fuel1">
       <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">1</p>
      <h4>Penalties </h4>
       <p>If you don't comply with DOT regulations, you may face a penalty ranging from hundreds to thousands of dollars. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">2</p>
      <h4>Operating authority loss </h4>
       <p>Non-compliance with trucking regulations can lead to the cancellation of a company's operating authority. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">3</p>
      <h4>Negative reputation </h4>
       <p>Your failure to comply with DOT regulations may lead to a loss of trust among your clients. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">4</p>
      <h4>Legal consequences</h4>
       <p>Businesses that do not adhere to trucking rules may face legal action, resulting in increased costs. </p>
       </div>
    </div>
  </div>
  </div>
</section>

<section className="abt-feature">
  <div className="container">
  <div className="row text-center">
    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
    <h2>Trucking compliance services we offer</h2>
    <p className="established3">At NLS, we provide a wide range of trucking compliance services to help you enhance your safety standards and maintain compliance with trucking regulations. Whether you need assistance in filing MC numbers or preparing for a new entrants audit, we can help you with all your compliance needs. </p>
    </div>
      </div>
      <div className="row">
      <div className="wrapper-box">
  <div className="outer-box">
    <div className="row m-0">
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={authority} className="response" alt="" />
                   </div>
        <div className="inner-box">
          <h4>Operating authority </h4>
          <div className="text">
          We help trucking companies obtain interstate or intrastate operating permits, including MC numbers. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={expertise} className="response" alt="" />
           </div>
        <div className="inner-box">
           <h4>Driver qualification files </h4>
          <div className="text">
          We will gather and prepare driver qualification files in compliance with regulatory standards. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={drugs} className="response" alt="" />
                    </div>
        <div className="inner-box">
           <h4>US DOT </h4>
          <div className="text">
          We assist you in obtaining and managing USDOT numbers for full regulatory compliance. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={fastdelivery} className="response" alt="" />
                    </div>

        <div className="inner-box">
          <h4> BOC 3</h4>
          <div className="text">
          Our expert team will assist you in filing your BOC 3, along with handling all paperwork and legal requirements. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={audit} className="response" alt="" />
                    </div>
        <div className="inner-box">
           <h4>New entrants audit</h4>
          <div className="text">
          We help new trucking businesses obtain the necessary permits for meeting DOT obligations. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
        <div className="inner-box">
          <div className="icon">
          <img src={eSupport} className="response" alt="" />
          </div>
          <h4>Driver Training</h4>
          <div className="text">
          We provide training sessions to help keep drivers compliant with FMCSA regulations. 
            accepted.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
    
  </div>
</section>
<Compliancebenefits />

<FooterTwo />
      </Suspense>
       </Fragment>
    </>
  );
};

export default Compliance;
