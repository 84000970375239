import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import circlehubspot from "../nlsimages/circlehubspot.png";


const Insurancebenefits = () => {
 
  return (
    <>
<section className="Fuelcardbenefits-tab">
     <div className="container">
     <div className="row text-center">
    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12"> 
    <h2>Maximum coverage at Lowest rates </h2>
    <p className="established3">At National Logistics Star, we pride ourselves on offering a perfect blend of vast coverage and affordability with our  <br />trucking insurance services. With us, you can drive confidently, knowing that you’ve got high-quality insurance coverage that  <br />offers the utmost security. </p>
    </div>
      </div>
        <Tabs>
     <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-7 col-md-6 col-12'>
        
                    <TabList>
      <Tab><span className='goi'>01</span><br />Affordable <br /> rates </Tab>
      <Tab><span className='goi'>02</span><br />Quick <br />Quote</Tab>
      <Tab><span className='goi'>03</span><br />Comprehensive <br />insurance</Tab>
      <Tab><span className='goi'>04</span><br />24/7 <br />support </Tab>
     
    </TabList>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-5 col-md-6 col-12'>
        <TabPanel>
        <div className="service iconbox  icon-box-style8">
    <div className="title-wrap">
  <h6>Affordable rates </h6>
    <div className="content">
      <p>
      Get the best coverage at the lowest rates, ensuring maximum protection without straining your budget. 
      </p>
      </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  
  <div className="title-wrap">
  <h6>Quick Quote </h6>
    <div className="content">
      <p>
      With NLS, you’ll get your insurance quote in minutes and enjoy the benefits of quick, hassle-free services while saving your time. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  <div className="title-wrap">
  <h6>Comprehensive Protection  </h6>
    <div className="content">
      <p>
      With our trucking insurance services, you’ll get comprehensive protection against all kinds of damages and risks. 
 </p>
     
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  <div className="title-wrap">
  <h6>24/7 support </h6>
    <div className="content">
      <p>
      Our dedicated experts are available round the clock to address your inquiries, concerns and insurance requirements. 

      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
        </div>
     </div>
</Tabs>
</div>
</section>
    </>
  );
};

export default Insurancebenefits;
