import React, { Fragment, Suspense } from "react";
import FooterTwo from "../components/FooterTwo";
import MyHeader from "../components/MyHeadr";

const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));


const Termsandconditions = () => {
  return (
    <>
      <Fragment>
      <Suspense >
       <SupportBarOne />
      <MyHeader />
      <section className="Logistics-policy-bnr">
      <div className="container">
        <div className="row">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
        <h2>Terms of Service</h2>
        </div>
        </div>
      </div>
      </section>
<section className="Logistics-policy">
    <div className="container">
        <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                <h2>Terms of Service</h2>
                <p>Welcome to National Logistics Star! These Terms of Service (“Agreement”) govern your use of our Services, including our Site, Products, and Services, which may be provided with or without the use of the Site. The terms “Provider,” “we,” or “us” refer to National Logistics Stars and its Affiliates. By accessing or using our website, you agree to comply with and be bound by this Agreement. Please read these terms carefully before using our website. If you do not agree with any part of these terms, you may not access or use our website. </p>
                <h3>Website usage</h3>
                <p>By using our website, you agree not to: </p>
                <ul className="agree">
                <li>Share false, misleading, or harmful content, including deceptive, abusive, or offensive material.</li>
                <li>Share copyrighted or confidential information without authorization.</li>
                <li>Engage in illegal activities or fraudulent practices. </li>
                <li>Indulge in spam, impersonation, or disruptive behavior.</li>
                <li> Disrupt or damage our website.</li>
                <li>Introduce malicious software or attempt unauthorized access. 
By using our Services, you agree to abide by these guidelines. Violations of these terms may result in account suspension or legal action.
</li>
<h3>Intellectual property rights</h3>
<p>The intellectual property contained within our website, including but not limited to trademarks, trade names, logos, and copyrighted materials, are the exclusive property of National Logistics Stars. Users are prohibited from using, reproducing, distributing, or modifying any of our intellectual property without prior written consent.</p>
<p>By using our website, you agree to not infringe upon our intellectual property rights or engage in any activity that could compromise the integrity of our trademarks, copyrights, or other proprietary information. Any unauthorized use or reproduction of our intellectual property is strictly prohibited and may result in legal action or termination of access to our website. </p>
<h3>Disclaimer</h3>
<p>The information provided on our website is for general purposes only. We do not guarantee its accuracy, completeness, or suitability for any specific purpose. Your reliance on this information is at their own risk. We are not liable for any losses or damages resulting from the use of this information. </p>
<h3>Limitation of Liability</h3>
<p>We strive to provide accurate and reliable information. However, we do not guarantee its completeness or suitability for your specific needs. In no event shall National Logistics Stars be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with the use of this website or our services. We are not responsible for any loss, injury, or inconvenience sustained by you.</p>
<h3>Indemnification</h3>
<p>You agree to indemnify and hold National Logistics Stars and its affiliates, officers, agents, and employees harmless from any claims, demands, losses, liabilities, and expenses (including attorneys' fees) arising out of or in connection with the use of our website and its services, violation of these terms, or infringement of any intellectual property or other rights of any person or entity. You consent to reimburse us for any costs incurred due to such claims.</p>
<h3>Waiver </h3>
<p>Our failure to enforce any provision of these Terms of Service does not waive our right to enforce that provision or any other provision in the future. The course of conduct between you and us, as well as trade practices, will not modify any provision of these Terms.</p>
<p>If any provision is found to be invalid or unenforceable, it will be separated from these Terms, and the remaining provisions will remain valid and enforceable. Any rights not explicitly granted in these Terms are reserved to National Logistics Stars.  
</p>
<h3>Termination</h3>
<p>We may terminate or suspend your access to the website and services at our sole discretion, without prior notice, for any reason, including a breach of this agreement.</p>
<h3>Privacy Policy</h3>
<p>Your use of the Services is also governed by our Privacy Policy. Please review our Privacy Policy to understand how we collect, use, and disclose your information.</p>
<h3>Modification</h3>
<p>We reserve the right to modify the Terms Of Use at any time. Any changes will be effective immediately upon posting on our Site. Your continued use of the website after any modifications constitutes your acceptance of the revised terms.</p>
<h3>Governing Law</h3>
<p>This Agreement is governed by and construed in accordance with the laws of the United States. Any legal action or proceeding arising out of or related to these terms shall be brought exclusively in the federal or state courts of Ohio, United States </p>
<h3>Contact Us</h3>
<p>If you have any questions or concerns about these Terms of Service, please contact us at <a href="malto:info@nationallogisticsstar.com">info@nationallogisticsstar.com </a></p>

                </ul>

            </div>
        </div>
    </div>
</section>

<FooterTwo />
      </Suspense>
       </Fragment>
    </>
  );
};

export default Termsandconditions;
