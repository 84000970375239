import React, { Fragment, Suspense } from "react";
import downarrow from "../nlsimages/downarrow.png";
import eSupport from "../nlsimages/eSupport.png";
import radar from "../nlsimages/radar.png";
import insurance000 from "../nlsimages/insurance000.png";
import deliverytruck from "../nlsimages/deliverytruck.png";
import shipped from "../nlsimages/shipped.png";
import accident from "../nlsimages/accident.png";
import ins01 from "../../src/nlsimages/ins01.webp";
import insurnace00 from "../nlsimages/insurnace00.webp";
import FooterTwo from "../components/FooterTwo";
import MyHeader from "../components/MyHeadr";
import {Helmet} from "react-helmet";
import Insurancebenefits from "../components/Insurancebenefits";
const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));


const Insurance = () => {
  return (
    <>
      <Fragment>
      <Suspense >
       <SupportBarOne />
      <MyHeader />
      <Helmet>
       <meta charSet="utf-8" />
        <title>Trucking Insurance Services | Best Trucking Insurance Company – NLS</title>
        <meta name="description" content="Searching for the best trucking insurance company? At NLS, we provide the best trucking insurance services to safeguard your trucking business from risks and uncertainties." />
        <link rel="canonical" href="https://nationallogisticsstar.com/Insurance" />
            </Helmet>
<section className="insu-bnr" style={{ backgroundImage: "url(" + insurnace00 + ")" }}>
    <div className="container">
        <div className="row">
        <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-10 col-12">
                <div className="dispatch-content">
                <h1
                      className="bnr-title whitetitle"
                      data-animation="fadeInUp"
                      data-delay=".5s"
                    >Comprehensive coverage for Best Trucking Insurance and owner-operators </h1>
                    <div className="why-btn2">
                    <a class="btn btn-base" href="/contact-us">  Get A Quote <span>
                  <svg
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                      fill="currentColor"
                    />
                  </svg>
                 
                </span></a>
   </div>
                </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="dispatch-img"></div>
            </div>
        </div>
    </div>
</section>
<div className=" downarrow1">
  <img src={downarrow} className="downarrow" alt="" />
</div>
<section className="abt-insurance">
  <div className="container">
    <div className="row">
      <div className="col-lg-5 col-xxl-5 col-xl-5 col-md-5 col-12">
    <img src={ins01} alt="" className="distruck" />
      </div>
      <div className='col-lg-7 col-xxl-7 col-xl-7 col-md-7 col-12 align-self-center'>
                <div className='about-inner-wrap'>
                  <div className='section-title mb-0'>
                    <h2>We ensure your peace of mind at every mile. </h2>
                    <p className="reader">At National Logistics Star, we understand the urgency of keeping the big wheels turning without worries. Unforeseen accidents or crashes can not only bring your trucking journey to a halt but also pose a serious risk to your life. </p>

                    <p className="reader">With our vast insurance coverage, you can get the peace of mind you need on the road. We’ve got you protected from a range of damages, leaving you with fewer worries and more focus on the road ahead. </p>

                    <p className="reader">We collaborate with the best insurance providers to help you get maximum coverage options at minimum cost. Get in touch with us to enjoy a stress-free trucking journey. 
                      </p>
                    <div className="why-btn3">
                    <a class="btn btn-base" href="/contact-us"><span></span> Get A Quote</a>
                    </div>         
                
                  </div>
                </div>
              </div>
    </div>
  </div>
</section>
<hr />
<section className="fuel-feature">
  <div className="container">
  <div className="row" id="fuel1">
       <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">1</p>
      <h4>Discuss your needs </h4>
       <p>Contact our team to discuss the type and amount of coverage you need. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">2</p>
      <h4>Expert Guidance </h4>
       <p>Our team will explain the insurance options available for you. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">3</p>
      <h4>Customization </h4>
       <p>Our experts will customize the offers that best suit your needs. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">4</p>
      <h4> Hit the road</h4>
       <p>You can drive with confidence, knowing that you’re protected. </p>
       </div>
    </div>
  </div>
  </div>
</section>

<section className="abt-feature">
  <div className="container">
  <div className="row text-center">
    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
    <h2>We have the coverage to keep you <br />protected on the road</h2>
    <p className="established3">With our wide range of trucking insurance services, we aim to keep you protected while<br /> you hit the road. From safeguarding your trucks to ensuring your protection, we have solutions for your every need.  </p>
    </div>
      </div>
      <div className="row">
      <div className="wrapper-box">
  <div className="outer-box">
    <div className="row m-0">
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={radar} className="response" alt="" />
                   </div>
        <div className="inner-box">
         <h4>General liability coverage </h4>
          <div className="text">
          We safeguard you against third-party injuries and property damages. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={insurance000} className="response" alt="" />
                    </div>
        <div className="inner-box">
         <h4>Non-trucking liability coverage </h4>
          <div className="text">
          This coverage protects you when you use your truck for personal reasons. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={shipped} className="response" alt="" />
                    </div>
        <div className="inner-box">
           <h4>Bobtail insurance </h4>
          <div className="text">
          We have your truck covered when it's being used without a trailer. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={deliverytruck} className="response" alt="" />
             </div>
        <div className="inner-box">
           <h4>Cargo insurance </h4>
          <div className="text">
          We will keep your cargo protected from theft, damage or loss during transit. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={accident} className="response" alt="" />
             </div>
        <div className="inner-box">
            <h4>Physical damage cover </h4>
          <div className="text">
          Get coverage against damage resulting from accidents, collisions, or overturns. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={eSupport} className="response" alt="" />
             </div>
        <div className="inner-box">
          <h4>Trailer interchange insurance </h4>
          <div className="text">
          This insurance cover ensures your trailer protection during interchanges or transfers. 

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
    
  </div>
</section>
<Insurancebenefits />

<FooterTwo />
      </Suspense>
       </Fragment>
    </>
  );
};

export default Insurance;
