import React, { Fragment, Suspense } from "react";
import downarrow from "../nlsimages/downarrow.png";
import booking from "../nlsimages/booking.png";
import packets from "../nlsimages/packets.png";
import paperwork from "../nlsimages/paperwork.png";
import driver from "../nlsimages/driver.png";
import prison from "../nlsimages/prison.png";
import outbox from "../nlsimages/outbox.png";
import dispatching01 from "../../src/nlsimages/dispatching01.webp";
import disbnr from "../nlsimages/disbnr.webp";
import FooterTwo from "../components/FooterTwo";
import MyHeader from "../components/MyHeadr";
import {Helmet} from "react-helmet";
import Dispatchingbenefits from "../components/Dispatchingbenefits";
const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));


const Dispatching = () => {
  return (
    <>
      <Fragment>
      <Suspense >
       <SupportBarOne />
<MyHeader />
<Helmet>
       <meta charSet="utf-8" />
        <title>Truck Dispatch Services | Freight Dispatch Service – NLS</title>
        <meta name="description" content="At NLS, we provide reliable and cost-effective truck dispatch services in the USA. Our freight dispatch services can help you optimize your costs and maximize earnings." />
        <link rel="canonical" href="https://nationallogisticsstar.com/Dispatching" />
            </Helmet>
<section className="dis-bnr" style={{ backgroundImage: "url(" + disbnr + ")" }}>
    <div className="container">
        <div className="row">
        <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-10 col-12">
                <div className="dispatch-content">
                <h1
                      className="bnr-title whitetitle"
                      data-animation="fadeInUp"
                      data-delay=".5s"
                    >Simplify load finding, rate negotiation, and paperwork with truck dispatch services
                    </h1>
                    <div className="why-btn2">
  <a class="btn btn-base" href="/contact-us"><span></span> Get A Quote</a>
   </div>
                </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="dispatch-img"></div>
            </div>
        </div>
    </div>
</section>
<div className=" downarrow1">
  <img src={downarrow} className="downarrow" alt="" />
</div>
<section className="abt-insurance">
  <div className="container">
    <div className="row">
      <div className="col-lg-6 col-xxl-6 col-xl-6 col-md-6 col-12">
    <img src={dispatching01} alt="" className="distruck" />
      </div>
      <div className='col-lg-6 col-xxl-6 col-xl-6 col-md-6 col-12 align-self-center'>
                <div className='about-inner-wrap'>
                  <div className='section-title mb-0'>
                    <h2>
                    Trusted dispatch services that drive your business forward
                    </h2>
                    <p className="reader">National Logistics Star is a one-stop destination for professional truck dispatch services for owner operations, carriers and trucking companies who are frustrated with handling back-office operations and tired of wasting their time on low-paying freight. </p>

                    <p className="reader">We streamline your operations, saving you from the hassles of finding loads, negotiating rates, handling paperwork and much more. Our dedicated team takes full responsibility for supporting drivers, resolving issues and booking loads around the clock. </p>

                    <p className="reader">With NLS, you’re guaranteed to achieve maximum profits that will elevate your business growth. </p>
                    <div className="why-btn3">
                    <a class="btn btn-base" href="/contact-us">  Get A Quote <span>
                  <svg
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                      fill="currentColor"
                    />
                  </svg>
                 
                </span></a>
                    </div>         
                
                  </div>
                </div>
              </div>
    </div>
  </div>
</section>
<hr />
<section className="fuel-feature">
  <div className="container">
  <div className="row" id="fuel1">
       <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">1</p>
      <h4>Speak to our dispatchers </h4>
       <p>Let us know your details, including name, email address, business type and fleet size. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">2</p>
      <h4>Share documents </h4>
       <p>Send us copies of your documents, including MC Authority, W9, Insurance etc.</p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">3</p>
      <h4>Agreement & Onboarding </h4>
       <p>We’ll provide you with an agreement outlining the terms as well as the scope of services. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">4</p>
      <h4>Start hauling </h4>
       <p>Once you sign the contract, you can start hauling loads, while we will begin dispatching for you. </p>
       </div>
    </div>
  </div>
  </div>
</section>

<section className="abt-feature">
  <div className="container">
  <div className="row text-center">
    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
    <h2>Truck dispatching services that make your haul life easier. </h2>
    <p className="established3">We understand the worries and stress that truckers face on a daily basis- being stuck at truck stops for endless hours, scouting loads boards, dealing with brokers, waiting for emails and the hassles of paying for taxes. All that ends now!</p>
    </div>
      </div>
      <div className="row">
      <div className="wrapper-box">
  <div className="outer-box">
    <div className="row m-0">
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={booking} className="response" alt="" />
                   </div>
        <div className="inner-box">
            <h4>Booking loads </h4>
          <div className="text">
          Our team finds the highest-paying loads and negotiates to get the best rate per mile. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={packets} className="response" alt="" />
                   </div>
        <div className="inner-box">
          <h4>Setting up packets </h4>
          <div className="text">
          We will set up all broker's packets to ensure your stress-free journey on the road. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={paperwork} className="response" alt="" />
                    </div>
        <div className="inner-box">
          <h4>Paperwork completion </h4>
          <div className="text">
          We will take care of all the paperwork, including rate confirmations, factoring, BOL and more. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={driver} className="response" alt="" />
                  </div>
        <div className="inner-box">
         <h4>Driver assistance </h4>
          <div className="text">
          From load information to route guidance and breakdown assistance, we’ll help you at every step. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={prison} className="response" alt="" />
                    </div>
        <div className="inner-box">
           <h4>Lumper/ Detention </h4>
          <div className="text">
          Our team will handle lumper fees along with detention times to ensure smooth operations. 
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 whay-choose-block">
      <div className="icon">
          <img src={outbox} className="response" alt="" />
          </div>
        <div className="inner-box">
           <h4>Phone/email updates </h4>
          <div className="text">
          We will take care of your phone and emails, providing you with regular updates in addition to resolving issues.  
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
    
  </div>
</section>
<Dispatchingbenefits />

<FooterTwo />
      </Suspense>
       </Fragment>
    </>
  );
};

export default Dispatching;
