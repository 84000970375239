import React, { Fragment, Suspense } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import smallreefer from "../nlsimages/smallreefer.png";
import smallflatbed from "../nlsimages/smallflatbed.png";
import FuelCard from "../../src/nlsimages/FuelCard.png";
import Factoring01 from "../../src/nlsimages/Factoring01.png";
import dispatch from "../../src/nlsimages/dispatch.png";
import brainstorming from "../nlsimages/brainstorming.png";
import trckload from "../../src/nlsimages/trckload.png";
import dedication from "../../src/nlsimages/dedication.png";
import banned from "../../src/nlsimages/banned.png";
import percent from "../../src/nlsimages/percent.png";
import contract from "../../src/nlsimages/contract.png";
import credit from "../nlsimages/credit.png";
import cupblue from "../../src/nlsimages/cupblue.png";
import downarrow from "../nlsimages/downarrow.png";
import first from "../../src/nlsimages/first.webp";
import ProfitCalculator from "../components/Calculator";
import Featurefaq from "../components/Featurefaq";
import FooterTwo from "../components/FooterTwo";
import Newserivces from "../components/Newserivces";
import Cta from "../components/Cta";
import MyHeader from "../components/MyHeadr";
import { Helmet } from "react-helmet";
const NavbarThree = React.lazy(() => import("../components/NavbarThree"));
const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));

const Home = () => {
  return (
    <>
      <Fragment>
        <Suspense>
          <SupportBarOne />
          <MyHeader />
          <Helmet>
            <meta charSet="utf-8" />
            <title>Trucking Logistics Company | Logistics Trucking Services</title>
            <meta
              name="description"
              content="Looking for a trucking logistics company in the USA? Contact us to get the best logistics trucking services that will help streamline your trucking operations."
            />
            <link rel="canonical" href="https://nationallogisticsstar.com" />
          </Helmet>

          <section className="mybnr">
            <div className="container">
              <div className="row">
                <div className="col-xxl-7">
                  <div className="bnr-background">
                    <h1
                      className="bnr-title"
                      data-animation="fadeInUp"
                      data-delay=".5s"
                    >
                      Trucking <span className="worry"> logistics company </span> that
                      help you drive with ease{" "}
                    </h1>
                    <p className="perfec3t">
                      Drive worry-free. We’ve got you covered for all your
                      trucking needs.{" "}
                    </p>
                  </div>
                  <div className="banner-box">
                    <ul>
                      <li>5+ Years on market </li>
                      <li>50+ Dedicated dispatchers </li>
                      <li>180+ Current trucks </li>
                    </ul>
                  </div>
                  <a class="btn btn-base" href="/contact-us">
                    {" "}
                    Get A Quote{" "}
                    <span>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </a>
                </div>
                <div className="col-xxl-5"></div>
              </div>
            </div>
            <img src={first} className="bannertwo" alt="" />
          </section>

          <div className=" downarrow1">
            <img src={downarrow} className="downarrow" alt="" />
          </div>

          <Newserivces />
          <hr />

          <section className="whynls">
            <div className="container">
              <div className="row">
                <div className="col-xxl-7 col-12">
                  <h2>Leading you towards more profit and less hassle </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12 why-choose-block">
                  <div
                    className="inner-box wow fadeInUp animated"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="icon">
                      <img
                        src={brainstorming}
                        className="brainstorming"
                        alt=""
                      />
                      {/* <img src={idea1} className="hoverimg" alt="" /> */}
                      <h4>Preferred lanes</h4>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12 why-choose-block">
                  <div
                    className="inner-box wow fadeInUp animated"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="icon">
                      <img src={trckload} className="brainstorming" alt="" />
                      {/* <img src={trckloadwhite} className="hoverimg" alt="" /> */}
                      <h4>Top paying loads </h4>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12 why-choose-block">
                  <div
                    className="inner-box wow fadeInUp animated"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="icon">
                      <img src={dedication} className="brainstorming" alt="" />
                      {/* <img src={dedication1} className="hoverimg" alt="" /> */}
                      <h4>Compliant assurance </h4>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12 why-choose-block">
                  <div
                    className="inner-box wow fadeInUp animated"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="icon">
                      <img src={banned} className="brainstorming" alt="" />
                      {/* <img src={banned1} className="hoverimg" alt="" /> */}
                      <h4>No forced dispatch </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12 why-choose-block">
                  <div
                    className="inner-box wow fadeInUp animated"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="icon">
                      <img src={percent} className="brainstorming" alt="" />
                      {/* <img src={percentwhite} className="hoverimg" alt="" /> */}
                      <h4>Steady Funds</h4>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12 why-choose-block">
                  <div
                    className="inner-box wow fadeInUp animated"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="icon">
                      <img src={cupblue} className="brainstorming" alt="" />
                      {/* <img src={cup} className="hoverimg" alt="" /> */}
                      <h4>24/7 support </h4>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12 why-choose-block">
                  <div
                    className="inner-box wow fadeInUp animated"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="icon">
                      <img src={credit} className="brainstorming" alt="" />
                      {/* <img src={credit1} className="hoverimg" alt="" /> */}
                      <h4>Highest rate negotiations </h4>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12 why-choose-block">
                  <div
                    className="inner-box wow fadeInUp animated"
                    data-wow-duration="1500ms"
                    style={{
                      visibility: "visible",
                      animationDuration: "1500ms",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="icon">
                      <img src={contract} className="brainstorming" alt="" />

                      <h4>Exclusive fuel discounts </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <ProfitCalculator /> */}

          <section className="howworks">
            <div className="container">
              <div className="row">
                <div className="col-xxl-5 col-xl-5 col-md-12 col-12">
                  <div className="work">
                    <h2 className="How">
                      Getting <span className="darkblue"> started</span> is easy{" "}
                    </h2>
                    <svg
                      width="150px"
                      height="150px"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.64645 11.3536C3.45118 11.1583 3.45118 10.8417 3.64645 10.6465L10.2929 4L6 4C5.72386 4 5.5 3.77614 5.5 3.5C5.5 3.22386 5.72386 3 6 3L11.5 3C11.6326 3 11.7598 3.05268 11.8536 3.14645C11.9473 3.24022 12 3.36739 12 3.5L12 9.00001C12 9.27615 11.7761 9.50001 11.5 9.50001C11.2239 9.50001 11 9.27615 11 9.00001V4.70711L4.35355 11.3536C4.15829 11.5488 3.84171 11.5488 3.64645 11.3536Z"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                </div>
                <div className="col-xxl-7 col-xl-7 col-md-12 col-12">
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                      <div className="facts">
                        <p className="stp">1</p>
                        <h4>Discuss your needs with our team</h4>
                        <p className="smallcontent">
                          Let us know your business goals, fleet size and unique
                          challenges to help us understand your requirements.
                        </p>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                      <div className="facts">
                        <p className="stp">2</p>
                        <h4>Setup with us</h4>
                        <p className="smallcontent">
                          Our team will guide you through the essential
                          paperwork and documents required for the onboarding
                          process.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                      <div className="facts">
                        <p className="stp">3</p>
                        <h4>Sign the agreement</h4>
                        <p className="smallcontent">
                          Next, you’ll have to sign a contract with us. The
                          agreement outlines the scope of our services, terms
                          and expectations.
                        </p>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                      <div className="facts">
                        <p className="stp">4</p>
                        <h4>Begin rolling with us</h4>
                        <p className="smallcontent">
                          That's it. Now you can plan your routes, select the
                          best load and enjoy a hassle-free trucking experience.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="trhplescroll">
            <div className="container">
              <div className="row text-center">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                  <h2 className="diffent">
                    Our unique features that help us make your every mile count{" "}
                  </h2>
                  <p className="content-serivce">
                    At NLS, we make sure that every mile counts towards your
                    success. Discover our key <br />
                    features that help us keep your wheels rolling and your
                    revenue growing.{" "}
                  </p>
                  <div className="ex3">
                    <Featurefaq />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="dispatcher-result">
            <div className="container">
              <div className="row">
                <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12">
                  <div className="reader">
                    <h2>Discover our remarkable results </h2>
                    <p className="lihed">
                      Our skilled dispatchers have consistently delivered
                      exceptional results that have increased profits and
                      reduced costs for our clients.{" "}
                    </p>
                  </div>
                </div>
                <div
                  className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12"
                  id="nill"
                ></div>
              </div>
              <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                  <Tabs>
                    <TabList>
                      <Tab>Dry Van</Tab>
                      <Tab>Flatbed</Tab>
                      <Tab>Reefer</Tab>
                      <Tab>Power Only</Tab>
                    </TabList>
                    <TabPanel>
                      <div className="row" id="be">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-7 col-12">
                          <table className="table table-bordered ">
                            <thead>
                              <tr>
                                <th className="n-result">Month</th>
                                <th className="n-result">DAT</th>
                                <th className="n-result">Our Results</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>December 2021</td>
                                <td>
                                  <span>$3.99</span>
                                </td>
                                <td>
                                  <span>
                                    <strong>$4.22</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>January 2022</td>
                                <td>
                                  <span>$2.10</span>
                                </td>
                                <td>
                                  <span>
                                    <strong>$3.10</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>February 2022</td>
                                <td>$3.50</td>
                                <td>
                                  <span>
                                    <strong>$4.2</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>July 2022</td>
                                <td>$2.05</td>
                                <td>
                                  {" "}
                                  <span>
                                    <strong>$3.05</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>December 2022</td>
                                <td>
                                  <span>$3.05</span>
                                </td>
                                <td>
                                  <span>
                                    <strong>$3.45</strong>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-5 col-12">
                          <div className="resultchart">
                            <img
                              src={smallreefer}
                              className="smallreffer"
                              alt=""
                            />
                            <p className="Result-a">Amount Result</p>
                            <h3 className="totalresult">$4.22</h3>
                            <div className="pergross">
                              <div className="left-t">
                                <p className="monthly">
                                  <strong>2.5%</strong>
                                </p>
                                <p className="Result-P">Monthly Result</p>
                              </div>
                              <div className="right-t">
                                <p className="monthly">
                                  <strong>4.5%</strong>
                                </p>
                                <p className="Result-P">Per-gross</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="row" id="be">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                          <table className="table table-bordered ">
                            <thead>
                              <tr>
                                <th className="n-result">Month</th>
                                <th className="n-result">DAT</th>
                                <th className="n-result">Our Results</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>March 2021</td>
                                <td>
                                  <span>$1.99</span>
                                </td>
                                <td>
                                  <span>
                                    <strong>$2.22</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>August 2022</td>
                                <td>
                                  <span>$3.10</span>
                                </td>
                                <td>
                                  <span>
                                    <strong>$3.05</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>September 2022</td>
                                <td>$2.50</td>
                                <td>
                                  <span>
                                    <strong>$4.2</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>November 2022</td>
                                <td>$4.05</td>
                                <td>
                                  <span>
                                    <strong>$5.05</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>December 2022</td>
                                <td>
                                  <span>$3.05</span>
                                </td>
                                <td>
                                  <span>
                                    <strong>$3.45</strong>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                          <div className="resultchart">
                            <img
                              src={smallflatbed}
                              className="smallreffer"
                              alt=""
                            />
                            <p className="Result-a">Amount Result</p>
                            <h3 className="totalresult">$3.10</h3>
                            <div className="pergross">
                              <div className="left-t">
                                <p className="monthly">
                                  <strong>2.5%</strong>
                                </p>
                                <p className="Result-P">Monthly Result</p>
                              </div>
                              <div className="right-t">
                                <p className="monthly">
                                  <strong>4.5%</strong>
                                </p>
                                <p className="Result-P">Per-gross</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="row" id="be">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                          <table className="table table-bordered ">
                            <thead>
                              <tr>
                                <th className="n-result">Month</th>
                                <th className="n-result">DAT</th>
                                <th className="n-result">Our Results</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>January 2023</td>
                                <td>
                                  <span>$2.99</span>
                                </td>
                                <td>
                                  <span>
                                    <strong>$2.22</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>March 2023</td>
                                <td>
                                  <span>$2.10</span>
                                </td>
                                <td>
                                  <span>
                                    <strong>$2.10</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>April 2023</td>
                                <td>$3.50</td>
                                <td>
                                  <span>
                                    <strong>$4.02</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>July 2023</td>
                                <td>$2.05</td>
                                <td>
                                  <span>
                                    <strong>$3.05</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>August 2023</td>
                                <td>
                                  <span>$3.05</span>
                                </td>
                                <td>
                                  <span>
                                    <strong>$5.45</strong>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                          <div className="resultchart">
                            <img
                              src={smallreefer}
                              className="smallreffer"
                              alt=""
                            />
                            <p className="Result-a">Amount Result</p>
                            <h3 className="totalresult">$4.2</h3>
                            <div className="pergross">
                              <div className="left-t">
                                <p className="monthly">
                                  <strong>2.5%</strong>
                                </p>
                                <p className="Result-P">Monthly Result</p>
                              </div>
                              <div className="right-t">
                                <p className="monthly">
                                  <strong>4.5%</strong>
                                </p>
                                <p className="Result-P">Per-gross</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="row" id="be">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                          <table className="table table-bordered ">
                            <thead>
                              <tr>
                                <th className="n-result">Month</th>
                                <th className="n-result">DAT</th>
                                <th className="n-result">Our Results</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>December 2021</td>
                                <td>
                                  <span>$3.99</span>
                                </td>
                                <td>
                                  <span>
                                    <strong>$4.22</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>January 2022</td>
                                <td>
                                  <span>$2.10</span>
                                </td>
                                <td>
                                  <span>
                                    <strong>$3.10</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>February 2022</td>
                                <td>$3.50</td>
                                <td>
                                  <span>
                                    <strong>$4.2</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>July 2022</td>
                                <td>$2.05</td>
                                <td>
                                  {" "}
                                  <span>
                                    <strong>$3.05</strong>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>December 2022</td>
                                <td>
                                  <span>$3.05</span>
                                </td>
                                <td>
                                  <span>
                                    <strong>$3.45</strong>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                          <div className="resultchart">
                            <img
                              src={smallreefer}
                              className="smallreffer"
                              alt=""
                            />
                            <p className="Result-a">Amount Result</p>
                            <h3 className="totalresult">$3.05</h3>
                            <div className="pergross">
                              <div className="left-t">
                                <p className="monthly">
                                  <strong>2.5%</strong>
                                </p>
                                <p className="Result-P">Monthly Result</p>
                              </div>
                              <div className="right-t">
                                <p className="monthly">
                                  <strong>4.5%</strong>
                                </p>
                                <p className="Result-P">Per-gross</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </section>
          <Cta />
          {/* <hr /> */}
          <section className="Started">
            <div className="container">
              <div className="row text-center">
                <div className="col-xx-12 col-xl-12 col-lg-12 col-md-12 col-12">
                  <h2 className="Not">
                    We’ve got solutions for every trucking business.
                  </h2>
                  <p className="content-serivce">
                    From small owner-operators with a single truck to large
                    fleet owners, having hundreds of <br />
                    trucks and brokers with a growing business, we have the
                    right solution for your needs.{" "}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="factury">
            <div className="container">
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                  <img src={FuelCard} className="Freightoring" alt="" />
                  <div className="Journey">
                    <span className="Journeyspan" />
                    <div className="ctitle-highlight " />
                    <h4>Owners Operators</h4>
                    <p>
                      Whether you're a beginner embarking on a trucking journey,
                      an experienced owner-operator, or anywhere in between, we
                      have got you covered for all your trucking hassles.
                    </p>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                  <img src={Factoring01} className="Freightoring" alt="" />
                  <div className="Journey">
                    <span className="Journeyspan" />
                    <div className="ctitle-highlight " />
                    <h4>Carriers</h4>
                    <p>
                      From comprehensive dispatch services ensuring on-time
                      delivery to seamless factoring for steady cash flow and
                      cost-saving fuel card services, we have got your back.
                    </p>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
                  <img src={dispatch} className="Freightoring" alt="" />
                  <div className="Journey">
                    <span className="Journeyspan" />
                    <div className="ctitle-highlight " />
                    <h4>Freight brokers</h4>
                    <p>
                      Our services can help make your life as a Freight broker
                      easier. With our guidance and support, you can widen your
                      network by building strong relationships with carriers and
                      shippers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <FooterTwo />
        </Suspense>
      </Fragment>
    </>
  );
};

export default Home;
