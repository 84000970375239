import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const Counteraboutpage = () => {
  return (
    <>
      {/*fact-area start*/}
      <div className='container'>
      <div className='row justify-content-center' id="fistcounter">
      <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12' id="allcount">
              <div className='single-fact-wrap'>
                <h2>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp delay={0} start={0} end={15000} />+
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <h4 className="impact">Carrier network </h4>
                <p>Our network of reliable carriers. </p>
              </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12' id="allcount">
              <div className='single-fact-wrap'>
                <h2>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp delay={0} start={0} end={180} />+
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <h4 className="impact">Active fleet</h4>
                <p>At present, we manage a fleet of more than 180 trucks. </p>
              </div>
            </div>
                    </div>
          <div className='row justify-content-center'>
          <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12' id="allcount">
              <div className='single-fact-wrap'>
                <h2>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp delay={0} start={0} end={50} />+
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <h4 className="impact">Dedicated dispatchers</h4>
                <p>Our crack team of expert dispatchers who continue to grow every month. </p>
              </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12' id="allcount">
              <div className='single-fact-wrap after-none'>
                <h2>
                  <TrackVisibility once>
                    {({ isVisible }) =>
                      isVisible && (
                        <span className='counter'>
                          <CountUp delay={0} start={0} end={1000} />+
                        </span>
                      )
                    }
                  </TrackVisibility>
                </h2>
                <h4 className="impact">brokers </h4>
                <p>Our vast network of carefully vetted brokers.</p>
              </div>
            </div>
          </div>
        </div>
     
      {/*fact-area end*/}
    </>
  );
};

export default Counteraboutpage;
