import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


const Compliancebenefits = () => {
 
  return (
    <>
<section className="Fuelcardbenefits-tab">
     <div className="container">
     <div className="row text-center">
    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12"> 
    <h2>We handle your compliance while you make bigger profits </h2>
    <p className="established3">Entrusting your compliance needs to National Logistics Star can help you manage other aspects of your business as well as achieve your business goals with confidence. Here are the benefits you can expect by partnering with us. 
</p>
    </div>
      </div>
        <Tabs>
     <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-7 col-md-6 col-12'>
                    <TabList>
      <Tab><span className='goi'>01</span><br />Save<br  className='save' /> time </Tab>
      <Tab><span className='goi'>02</span><br />Peace <br  className='save' /> of mind  </Tab>
      <Tab><span className='goi'>03</span><br />Avoid <br  className='save' /> penalties  </Tab>
      <Tab><span className='goi'>04</span><br />Grow your<br  className='save' /> business </Tab>
     
    </TabList>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-5 col-md-6 col-12'>
        <TabPanel>
        <div className="service iconbox  icon-box-style8">
    <div className="title-wrap">
  <h6>Save time </h6>
    <div className="content">
      <p>
      With our trucking compliance services, you can save valuable time and focus on managing crucial aspects of your business. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  
  <div className="title-wrap">
  <h6>Peace of mind  </h6>
    <div className="content">
      <p>
      You can get peace of mind, knowing that your trucking compliance needs are in trusted and capable hands. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  <div className="title-wrap">
  <h6>Avoid penalties </h6>
    <div className="content">
      <p>
      By having our professional team manage your trucking compliance, you can avoid costly fines and penalties. 

      </p>
     
    </div>
  </div>
</div>
    </TabPanel>
    <TabPanel>
    <div className="service iconbox  icon-box-style8">
  <div className="title-wrap">
  <h6>Grow your business </h6>
    <div className="content">
      <p>
      Whether you want to expand your trucking fleet or ensure that your trucks are compliant with safety regulations, with NLS, you can achieve these goals. Our services help you grow your business to new heights. 
      </p>
    
    </div>
  </div>
</div>
    </TabPanel>
        </div>
     </div>
</Tabs>
</div>
</section>
    </>
  );
};

export default Compliancebenefits;
