import React, { Fragment, Suspense } from "react";
import {
  FaCalculator,
  FaFileAlt,
  FaMapMarkerAlt,
  FaPencilAlt,
  FaPhoneAlt,
  FaRegEnvelope,
  FaUserAlt,
} from "react-icons/fa";
import conatctu00 from "../nlsimages/conatctu00.png";
import downarrow from "../nlsimages/downarrow.png";
import FooterTwo from "../components/FooterTwo";
import MyHeader from "../components/MyHeadr";
import { Helmet } from "react-helmet";
const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));

const Contactus = () => {
  return (
    <>
      <Fragment>
        <Suspense>
          <SupportBarOne />
          <MyHeader />
          <Helmet>
            <meta charSet="utf-8" />
            <title>
            Call at +1 254-334-7236 For Best Trucking Services,– NLS
            </title>
            <meta
              name="description"
              content="Seeking the best trucking services? Call NLS at +1 254-334-7236 for top-notch trucking services to streamline operations, reduce costs and improve profits."
            />
            <link
              rel="canonical"
              href=" https://nationallogisticsstar.com/Contact-us"
            />
          </Helmet>
          <section
            className="fulecard-bnr"
            style={{ backgroundImage: "url(" + conatctu00 + ")" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-10 col-12">
                  <div className="dispatch-content">
                    <h2>
                      Hi! We’re here to assist you with your inquiries and
                      concerns{" "}
                    </h2>
                    <div className="why-btn2"></div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                  <div className="dispatch-img"></div>
                </div>
              </div>
            </div>
          </section>
          <div className=" downarrow1">
            <img src={downarrow} className="downarrow" alt="" />
          </div>

          <section className="contactpage">
            <div className="container">
              <div className="row">
                <div
                  className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-12"
                  id="form-second-row"
                >
                  <div className="contactdetails">
                    <h2>Our contact information </h2>
                    <h6>Contact Number:</h6>
                    <div className="media">
                      <div className="icon">
                        <FaPhoneAlt />
                      </div>
                      <div className="media-body">
                        <p>
                          <a href="tel:+1 253-372-2778">+1 253-372-2778</a>
                        </p>
                      </div>
                    </div>
                    <h6>Mail Address:</h6>
                    <div className="media">
                      <div className="icon">
                        <FaRegEnvelope />
                      </div>
                      <div className="media-body">
                        <p>
                          <a href="mailto:info@nationallogisticsstar.com">
                            info@nationallogisticsstar.com
                          </a>
                        </p>
                        <p>
                          <a href="mailto:business@nationallogisticsstar.com">
                            business@nationallogisticsstar.com
                          </a>
                        </p>
                      </div>
                    </div>
                    <h6>Office Location:</h6>
                    <div className="media" id="lstbox">
                      <div className="icon">
                        <FaMapMarkerAlt />
                      </div>
                      <div className="media-body">
                        <p>507 DENALI PASS STE 601 CEDAR PARK, TX 78613</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-12">
                  <div className="myform">
                    <form
                      action="contact.php"
                      method="post"
                      encType="multipart/form-data"
                      id="contform"
                    >
                      <h2>Send us a message </h2>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="single-input-inner">
                            <label>
                              <FaUserAlt />
                            </label>
                            <input
                              type="text"
                              name="name"
                              placeholder="Name"
                              className="reg-input"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="single-input-inner">
                            <label>
                              <FaRegEnvelope />
                            </label>
                            <input
                              type="email"
                              name="email"
                              placeholder="Email"
                              className="reg-input"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="single-input-inner">
                            <label>
                              <FaCalculator />
                            </label>
                            <input
                              type="text"
                              name="phone"
                              placeholder="Phone No"
                              className="reg-input"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="single-input-inner">
                            <label>
                              <FaFileAlt />
                            </label>
                            <input
                              type="text"
                              name="subject"
                              placeholder="Subject"
                              className="reg-input"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="single-input-inner">
                            <label>
                              <FaPencilAlt />
                            </label>
                            <textarea
                              name="message"
                              placeholder="Write message"
                              defaultValue={""}
                              id="message"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <button className="btn btn-base" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <FooterTwo />
        </Suspense>
      </Fragment>
    </>
  );
};

export default Contactus;
