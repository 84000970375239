import React from "react";
import nlslogo from "../nlsimages/nlslogo.png";
import usatruck from "../../src/nlsimages/usatruck.webp";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaRegEnvelopeOpen,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Multiform from "./Multiform";
import MultiStepFormbottom from "./multiStepFormseo";

const FooterTwo = () => {
  return (
    <>
      {/* footer area start */}

      <section className="footer-form" id="ddd">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-xl-12">
              <div className="multiform">
                <h2 className="Want">Ready to move forward?</h2>
                <p>Fill in the form to take the next step with us. </p>
                <Multiform />
              </div>

              {/* <img src={usatruck} className="usatck" alt="" /> */}
            </div>
            <div className="col-lg-7 col-xl-7">
              {/* <MultiStepFormbottom /> */}
            </div>
          </div>
        </div>
      </section>

      <footer className="footer-area style-2">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-12">
              <div className="widget widget_about">
                <a href="/">
                  <img src={nlslogo} className="nlslogo" alt="" />
                </a>
                <div className="details">
                  <p className="supply">
                    National Logistics Star is a team of professionals helping
                    owner-operators and trucking companies with their trucking
                    needs.
                  </p>
                  <ul className="social-media style-border">
                    <li>
                      <a href="https://www.facebook.com/people/National-Logistics-Star/61555425780354/">
                        <FaFacebookF />
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">
                        <FaWhatsapp />
                      </a>
                    </li> */}
                    <li>
                      <a href="https://www.instagram.com/nationallogisticsstar/">
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-12">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Usefull Links</h4>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-12">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Our Services</h4>
                <ul>
                  <li>
                    <a href="/fuel-card">Fuel-card</a>
                  </li>
                  <li>
                    <a href="/dispatching">Dispatching</a>
                  </li>
                  <li>
                    <a href="/insurance">Insurance</a>
                  </li>
                  <li>
                    <a href="/fuel-advances">Fuel-advances</a>
                  </li>
                  <li>
                    <a href="/compliance">Compliance</a>
                  </li>
                  <li>
                    <a href="/factoring">Factoring</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-3 col-lg-3 col-xl-3 col-md-6 col-12">
              <div className="widget widget_contact">
                <h4 className="widget-title">Contact Us</h4>
                <div className="single-contact-inner">
                  <div className="icon">
                    <FaMapMarkerAlt />
                  </div>
                  <div className="details">
                    <p>507 DENALI PASS STE 601 CEDAR PARK, TX 78613</p>
                  </div>
                </div>
                <div className="single-contact-inner">
                  <div className="icon">
                    <FaMobileAlt />
                  </div>
                  <div className="details">
                    <p>
                      <a href="tel:+1 253-372-2778">+1 253-372-2778</a>
                    </p>
                  </div>
                </div>
                <div className="single-contact-inner">
                  <div className="icon">
                    <FaRegEnvelopeOpen />
                  </div>
                  <div className="details">
                    <p>
                      <a href="mailto:info@nationallogisticsstar.com">
                        info@nationallogisticsstar.com
                      </a>
                    </p>
                    <p>
                      <a href="mailto:business@nationallogisticsstar.com">
                        business@nationallogisticsstar.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        {/* Copyright Area Starts */}
        <div className="footer-copyright-area">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12">
                <div className="copyright-text">
                  <span>
                    © 2024{" "}
                    <a href="/" className="active">
                      National Logistics Star
                    </a>
                    - All rights reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Copyright Area End */}
      </footer>
      {/* footer area end */}
    </>
  );
};

export default FooterTwo;
