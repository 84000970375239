import { useState, useEffect } from "react";
import $ from 'jquery';



const Multiform = () => {
  
  return (
    
    <>
    <section className="multiform">
<div className="container-fluid">
  <div className="row justify-content-center">
    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12" id="appropriate">
      <div className="card ">
      <form 
   action="mail.php"
                      method="post"
                      encType="multipart/form-data"
                      id="contform">
                           <div className='row'>
                  <div className='col-md-6'>
                    <div className='single-input-inner'>
                    
                      <input type="text" name="name" placeholder="Company Name" className="reg-input" required />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='single-input-inner'>
                    <input type="number" name="mcdotnumber" placeholder="MC/DOT Number:*" className="reg-input" required />
                 
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='single-input-inner'>
                     
                      <input type="text" name="phone" placeholder="Phone No" className="reg-input" required />
                    </div>
                  </div>
                  <div className='col-md-6'>
                  <div className='single-input-inner'>
                  <input type="email" name="email" placeholder="Email" className="reg-input" required />
                
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='single-input-inner'>
                    
                      <textarea
                      className="reg-text"
                      name="message"
                      row="2"
                      placeholder="Your message"
                        defaultValue={""}
                        id='message'
                      />
                    </div>
                  </div>
                  <div className='col-12'>
                    <button className='btn btn-base' type='submit'>
             Submit
                    </button>
                  </div>
                </div>
              </form>
      </div>
    </div>
  </div>
</div>
</section>
    </>
  );
};

export default Multiform;
