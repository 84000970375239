import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import Blog from "./pages/blog";
import Faq from "./pages/faq";
import RouteScrollToTop from "./elements/RouteScrollToTop";
import Home from "./pages/home";
import Fuelcard from "./pages/fuel-card";
import Dispatching from "./pages/dispatching";
import Insurance from "./pages/insurance";
import Fueladvances from "./pages/fuel-advances";
import Contactus from "./pages/contact-us";
import Compliance from "./pages/compliance";
import Factoring from "./pages/factoring";
import Termsandconditions from "./pages/terms-and-conditions"
import Privacypolicy from "./pages/privacy-policy"
import Thankyou from "./pages/thank-you";
import Besttruckdispatch from "./pages/best-truck-dispatch-companies-in-the-usa ";
import Aboutus1 from "./pages/about-us";


function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <Routes>
      <Route exact path='/' element={<Home />} />
      <Route exact path='/thank-you' element={<Thankyou />} />
      <Route exact path='/privacy-policy' element={<Privacypolicy />} />
      <Route exact path='/terms-and-conditions' element={<Termsandconditions />} />
      <Route exact path='/factoring' element={<Factoring />} />
      <Route exact path='/compliance' element={<Compliance />} />
      <Route exact path='/contact-us' element={<Contactus />} />
      <Route exact path='/fuel-advances' element={<Fueladvances />} />
      <Route exact path='/insurance' element={<Insurance />} />
      <Route exact path='/dispatching' element={<Dispatching />} />
      <Route exact path='/about-us' element={<Aboutus1 />} />
      <Route exact path='/fuel-card' element={<Fuelcard />} />
      <Route exact path='/blog' element={<Blog />} />
      <Route exact path='/faq' element={<Faq />} />
      <Route exact path='/best-truck-dispatch-companies-in-the-usa' element={<Besttruckdispatch />} />
      </Routes>
      <ScrollToTop smooth color='#FA4318' />
    </BrowserRouter>
  );
}

export default App;
