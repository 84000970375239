import React, { useEffect } from "react";
import support from "../nlsimages/support.png";
import usatruck from "../../src/nlsimages/usatruck.png";
import menupick1 from "../nlsimages/menupick1.webp";
import menupick2 from "../nlsimages/menupick2.webp";
import menupick3 from "../nlsimages/menupick3.webp";
import menupick4 from "../nlsimages/menupick4.webp";
import menupick5 from "../nlsimages/menupick5.webp";
import menupick6 from "../nlsimages/menupick6.webp";
import nlslogo from "../nlsimages/nlslogo.png";
const MyHeader = () => {
  return (
    <section className="myheader">
      <div className="container-fluid">
        {/* ============= COMPONENT ============== */}
        <nav className="navbar navbar-expand-lg navbar-transparent ">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <img src={nlslogo} className="nlslogo" alt="" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#main_nav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="main_nav">
              <ul className="navbar-nav">
                <li className="nav-item active">
                  <a className="nav-link" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about-us">
                    About
                  </a>
                </li>

                <li className="nav-item dropdown has-megamenu">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Our Services
                  </a>
                  <div className="dropdown-menu megamenu" role="menu">
                    <div class="container">
                      <div class="row my-4">
                        <div class="col-md-6 col-lg-4 mb-4 mb-md-0">
                          {/* <div class="col-md-12 col-lg-4 mb-4 mb-lg-0"> */}
                          <a href="/fuel-card" class="text-dark">
                            <div class="row mb-4 border-bottom pb-2">
                              <div class="col-3">
                                <img
                                  src={menupick1}
                                  alt=""
                                  className="img-fluid shadow-1-strong rounded"
                                />
                              </div>
                              <div class="col-9">
                                <p class="mb-2">
                                  <strong>Fuel-card</strong>
                                </p>
                                <p>Enjoy big savings on fuel expenses</p>
                              </div>
                            </div>
                          </a>
                          <a href="/dispatching" class="text-dark">
                            <div class="row mb-4 border-bottom pb-2">
                              <div class="col-3">
                                <img
                                  src={menupick3}
                                  alt=""
                                  className="img-fluid shadow-1-strong rounded"
                                />
                              </div>
                              <div class="col-9">
                                <p class="mb-2">
                                  <strong>Dispatching</strong>
                                </p>
                                <p>24/7 reliable truck dispatching</p>
                              </div>
                            </div>
                          </a>
                          <a href="/insurance" class="text-dark">
                            <div class="row mb-4 border-bottom pb-2">
                              <div class="col-3">
                                <img
                                  src={menupick5}
                                  alt=""
                                  className="img-fluid shadow-1-strong rounded"
                                />
                              </div>
                              <div class="col-9">
                                <p class="mb-2">
                                  <strong>Insurance</strong>
                                </p>
                                <p>Get comprehensive on-road coverage </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="col-md-6 col-lg-4 mb-4 mb-md-0">
                          <a href="/fuel-advances" class="text-dark">
                            <div class="row mb-4 border-bottom pb-2">
                              <div class="col-3">
                                <img
                                  src={menupick2}
                                  alt=""
                                  className="img-fluid shadow-1-strong rounded"
                                />
                              </div>
                              <div class="col-9">
                                <p class="mb-2">
                                  <strong>Fuel-advances</strong>
                                </p>
                                <p>Get quick funds to cover fuel costs</p>
                              </div>
                            </div>
                          </a>
                          <a href="/compliance" class="text-dark">
                            <div class="row mb-4 border-bottom pb-2">
                              <div class="col-3">
                                <img
                                  src={menupick4}
                                  alt=""
                                  className="img-fluid shadow-1-strong rounded"
                                />
                              </div>
                              <div class="col-9">
                                <p class="mb-2">
                                  <strong>Compliance</strong>
                                </p>
                                <p>Stay compliant with trucking regulations</p>
                              </div>
                            </div>
                          </a>
                          <a href="/factoring" class="text-dark">
                            <div class="row mb-4 border-bottom pb-2">
                              <div class="col-3">
                                <img
                                  src={menupick6}
                                  alt=""
                                  className="img-fluid shadow-1-strong rounded"
                                />
                              </div>
                              <div class="col-9">
                                <p class="mb-2">
                                  <strong>Factoring</strong>
                                </p>
                                <p>Turn invoices into quick cash </p>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div class="col-md-6 col-lg-4 mb-4 mb-md-0">
                          <img src={usatruck} className="usatruck33" alt="" />
                        </div>
                      </div>
                    </div>
                    {/* end row */}
                  </div>{" "}
                  {/* dropdown-mega-menu.// */}
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contact-us">
                    Contact
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/blog">
                    Blog
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <div className="headercnt">
                    <div className="chatmsg">
                      <img src={support} alt="" className="chatmessage" />
                    </div>
                    <div className="box-body">
                      <h3 className="nav-info-box-title">Toll Free Number </h3>
                      <p className="Free33">
                        <a className="secondspan" href="tel:+1 253-372-2778">
                          +1 253-372-2778
                        </a>
                      </p>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a class="btn btn-base" href="/">
                    {" "}
                    Login{" "}
                    <span>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            {/* navbar-collapse.// */}
          </div>{" "}
          {/* container-fluid.// */}
        </nav>
      </div>
    </section>
  );
};

export default MyHeader;
