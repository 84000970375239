import React, { Fragment, Suspense } from "react";
import downarrow from "../nlsimages/downarrow.png";
import choose01 from "../nlsimages/choose01.webp";
import choose from "../nlsimages/choose.webp";

import web from "../nlsimages/web.png";
import fuelstation from "../nlsimages/fuelstation.png";
import fueladvance from "../nlsimages/fueladvance.png";
import checklist from "../nlsimages/checklist.png";
import truckinsurance from "../nlsimages/truckinsurance.png";
import process01 from "../../src/nlsimages/process01.png";
import {Helmet} from "react-helmet";
import FooterTwo from "../components/FooterTwo";
import Counteraboutpage from "../components/Counteraboutpage";
import MyHeader from "../components/MyHeadr";
const SupportBarOne = React.lazy(() => import("../components/SupportBarOne"));


const Aboutus1 = () => {
  return (
    <>
      <Fragment>
      <Suspense >
       <SupportBarOne />
<MyHeader />
<Helmet>
       <meta charSet="utf-8" />
        <title>About Us – National Logistics Star</title>
        <meta name="description" content="At National Logistics Star, we provide premier logistics trucking services for trucking companies in the USA. Contact us to learn more about us and our services." />
        <link rel="canonical" href="https://nationallogisticsstar.com/About-us" />
            </Helmet>
<section className="Aboutus-bnr">
    <div className="container">
        <div className="row">
            <div className="col-xxl-6 col-xl-8 col-lg-10 col-md-10 col-12">
                <div className="dispatch-content">
                    <h2>We’re your trusted partner for seamless trucking operations </h2>
                    <div className="why-btn2">
                    <a class="btn btn-base" href="/contact-us">  Get A Quote <span>
                  <svg
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                      fill="currentColor"
                    />
                  </svg>
                 
                </span></a>
   </div>
                </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
            <div className="dispatch-img"></div>
            </div>
        </div>
    </div>
</section>
<div className=" downarrow1">
  <img src={downarrow} className="downarrow" alt="" />
</div>
<section className="abt-insurance">
  <div className="container">
    <div className="row">
      <div className="col-lg-5 col-xxl-5 col-xl-5 col-md-5 col-12">
    <img src={choose01} alt="" className="Choose" />
      </div>
      <div className='col-lg-7 col-xxl-7 col-xl-7 col-md-7 col-12 align-self-center'>
                <div className='about-inner-wrap'>
                  <div className='section-title mb-0'>
                    <h2>
                    Who we are 
                    </h2>
                    <p className="reader">National Logistics Star is a team of experienced professionals in the trucking and logistics industry committed to helping trucking companies expand their horizons. Our journey began with a vision to revolutionize the trucking industry as well as facilitate growth for owner-operators, carriers, and large fleet owners with our diverse array of trucking solutions.
                    </p>
                    <p className="reader">With years of experience in the trucking industry, we understand your unique challenges and provide solutions to help you overcome them. Our services extend beyond truck dispatching- we provide factoring solutions, assistance with compliance and fuel cards offering discounts and rewards. </p>

                    <p className="reader">With NLS by your side, you’ll have a dedicated partner who will support you at every step of the way</p>
                    <div className="why-btn3">
                    <a class="btn btn-base" href="/contact-us">  Get A Quote <span>
                  <svg
                    width="22"
                    height="8"
                    viewBox="0 0 22 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                      fill="currentColor"
                    />
                  </svg>
                 
                </span></a>
                    </div>         
                
                  </div>
                </div>
              </div>
    </div>
  </div>
</section>
<hr />

<section className="serivces-home">
  <div className="container">
  <div className="row ">
<h2>Our core values </h2>
<p className="content-serivce">At NLS, we firmly believe that our core values are the key to not only our success but also our clients, partners and employees. By upholding our core values, we aim to propel your business forward. </p>
  </div>
  <div className="row">
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
    <div className="right">
  <div className="certificate-wrapper">
    <ul className="certificate-list focus-state-list-a">
      <li   className="certificate">
       <div className="icon">
          <img src={web} className="loadser" alt="" />
                    </div>
          <div className="info">
            <div className="title">Integrity </div>
            <div className="subtitle">At our core, we’re committed to ethical practices instead of taking an easy route. 
</div>
          </div>
       </li>
      <li   className="certificate">
     <div className="icon">
          <img src={process01} className="loadser" alt="" />
                    </div>
          <div className="info">
            <div className="title">Innovation</div>
            <div className="subtitle"> We embrace creativity and improvement to meet the evolving needs of our clients. 
</div>
          </div>
 
      </li>
      <li   className="certificate">
           <div className="icon">
          <img src={truckinsurance} className="loadser" alt="" />
                    </div>
          <div className="info">
            <div className="title">Commitment </div>
            <div className="subtitle">We strive to fulfil our commitments irrespective of the market conditions. 
</div>
          </div>
  
      </li>         
    </ul>
  </div>
  
</div>
    </div>
    <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
    <div className="right">
  <div className="certificate-wrapper">
    <ul className="certificate-list focus-state-list-a">
      
      <li   className="certificate">
     
          <div className="icon">
          <img src={fuelstation} className="loadser" alt="" />
           </div>
          <div className="info">
            <div className="title">Teamwork</div>
            <div className="subtitle">We work collaboratively towards one goal, i.e. providing highly effective trucking solutions. 
</div>
          </div>
 
      </li>
      <li   className="certificate">
         <div className="icon">
          <img src={fueladvance} className="loadser" alt="" />
                    </div>
          <div className="info">
            <div className="title">Reliability </div>
            <div className="subtitle">We stand as a dependable partner whom you can trust in most challenging situations. </div>
          </div>
         </li>
         <li className="certificate">
           <div className="icon">
          <img src={checklist} className="loadser" alt="" />
           </div>
          <div className="info">
            <div className="title">Excellence</div>
            <div className="subtitle">We aim for excellence in every aspect of our services. </div>
          </div>
        
     
      </li>
    </ul>
  </div>
  </div>
</div>
    </div>
  </div>
</section>
<section className="abour-counter">
  <div className="container">
    <div className="row">
      <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-12" id="counder">
        <div className="counter box">
         <h2 className="does">our  <span className="darkblue"> expertise </span>in numbers </h2>
         <svg width="150px" height="150px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.64645 11.3536C3.45118 11.1583 3.45118 10.8417 3.64645 10.6465L10.2929 4L6 4C5.72386 4 5.5 3.77614 5.5 3.5C5.5 3.22386 5.72386 3 6 3L11.5 3C11.6326 3 11.7598 3.05268 11.8536 3.14645C11.9473 3.24022 12 3.36739 12 3.5L12 9.00001C12 9.27615 11.7761 9.50001 11.5 9.50001C11.2239 9.50001 11 9.27615 11 9.00001V4.70711L4.35355 11.3536C4.15829 11.5488 3.84171 11.5488 3.64645 11.3536Z"
    fill="#fff"
  />
</svg>
        </div>
      </div>
      <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-12">
<Counteraboutpage />
      </div>
    </div>
  </div>
</section>
<section className="abt-insurance">
  <div className="container">
    <div className="row">
      <div className="col-lg-5 col-xxl-5 col-xl-5 col-md-5 col-12">
    <img src={choose} alt="" className="Choose" />
      </div>
      <div className='col-lg-7 col-xxl-7 col-xl-7 col-md-7 col-12 align-self-center'>
                <div className='about-inner-wrap'>
                  <div className='section-title mb-0'>
                    <h2>
                    We have a demonstrated history of helping trucking companies grow
                    </h2>
                    <p className="reader">We’re dedicated to helping trucking companies, owner-operators and carriers in their journey to grow their business. With a collective experience of more than 5 years in the trucking industry, we can say that we know how to maximize profits and minimize costs for our clients. </p>

                    <p className="reader">Throughout our journey, we’ve earned the trust of thousands of owner-operators, trucking companies and carriers by providing them with top-notch services that facilitated their business growth. </p>

                    <p className="reader">From small truckers to large fleet owners, we tailor our services to meet your unique challenges and pave the way for sustainable business growth. </p>
                    <div className="why-btn3">
                    <a class="btn btn-base" href="/contact-us"><span></span> Get A Quote</a>
                    </div>         
                
                  </div>
                </div>
              </div>
    </div>
  </div>
</section>
<hr />
<section className="fuel-feature">
  <div className="container">
  <div className="row" id="fuel1">
       <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">1</p>
      <h4>Customer centric-approach </h4>
       <p>Our every solution is tailored to the needs of our customers.  </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">2</p>
      <h4> Result-driven </h4>
       <p>Our focus is on delivering exceptional results that maximize your earnings. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">3</p>
      <h4>Proven experience </h4>
       <p>With 5+ years of experience, we have a proven track record of driving success. </p>
       </div>
    </div>
    <div className="col-xxl-3 col-xl-3 collg-3 col-md-6 col-12">
      <div className="bluefuelcard">
       <p className="stp">4</p>
      <h4>Transparent </h4>
       <p>We believe in being transparent in all facets of our business. </p>
       </div>
    </div>
  </div>
  </div>
</section>
<FooterTwo />
      </Suspense>
       </Fragment>
    </>
  );
};

export default Aboutus1;
